import { useAppSelector } from "../../../redux/hooks"
import { getRetrievesAnOrderState } from "../../../redux/retrievesAnOrder/retrievesAndOrderSlice"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { getPayReject } from "../../../utils/helpers/helperPayment"
import visaIcon from "../../../assets/icons/visa.svg"
import check from "../../../assets/icons/checkCircle.svg"
import denied from "../../../assets/icons/denied.svg"
import pending from "../../../assets/icons/pending.svg"
import americanIcon from "../../../assets/icons/American Express.svg"
import dinersIcon from "../../../assets/icons/Diners.svg"
import masterCardIcon from "../../../assets/icons/MasterCard.svg"
import { Params } from "../../../models/general"

let iconStatus = "",
  iconPay = ""

const EstadoCompraComponent = ({
  transactionStatus,
}: {
  transactionStatus: string
}) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const retrievesAnOrderState = useAppSelector(getRetrievesAnOrderState)
  const [pnr, setPnr] = useState("")
  const [cardNumber, setCardNumber] = useState("")
  const [totalPay, setTotalPay] = useState(0)
  const [currencyCode, setCurrencyCode] = useState("")
  const [textStatus, setTextStatus] = useState("")
  const [textPaid, setTextPaid] = useState("")

  useEffect(() => {
    getEstadoCompra()
  }, [retrievesAnOrderState])

  const getEstadoCompra = () => {
    let total = 0

    if (state !== null) {
      const paymentRecords =
        retrievesAnOrderState.retrievesAnOrder?.data?.paymentRecords
      let data: any = state
      let { pnr, nameCard } = data

      switch (nameCard) {
        case "AMEX":
          iconPay = americanIcon
          break
        case "DINERS":
          iconPay = dinersIcon
          break
        case "VISA":
          iconPay = visaIcon
          break
        case "MASTERCARD":
          iconPay = masterCardIcon
          break
      }

      if (transactionStatus === "SUCCESS") {
        let { authorizationCode } = data
        iconStatus = check
        setTextStatus(
          t("translation:Confirmation.estadoCompra.valueStatusSucces"),
        )
        setTextPaid(t("translation:Confirmation.estadoCompra.valueSucces"))
        setPnr(pnr)
        const paymentRecordsAuthorization = paymentRecords?.filter((pay) => {
          return (
            pay.paymentTransactions[0]?.authorization?.approvalCode ===
            authorizationCode
          )
        })
        if (paymentRecordsAuthorization?.length! > 0) {
          paymentRecordsAuthorization?.map((pra) => {
            total += pra.paymentTransactions[0].amount.value
            let cardNumber =
              pra.paymentTransactions[0].paymentMethod.cardNumber.replaceAll(
                "X",
                "",
              )
            cardNumber = "•••• •••• •••• " + cardNumber
            setCardNumber(cardNumber)
            setCurrencyCode(pra.paymentTransactions[0].amount.currencyCode)
          })
          setTotalPay(total)
        }
      } else if (transactionStatus === "REJECT") {
        let { cardNumber } = data
        iconStatus = denied
        setTextStatus(
          t("translation:Confirmation.estadoCompra.valueStatusReject"),
        )
        setTextPaid(
          t("translation:Confirmation.estadoCompra.valueRejectOrPending"),
        )
        const arrayCardNumber: any[] = String(cardNumber).split("-")
        cardNumber = arrayCardNumber[arrayCardNumber.length - 1]
        cardNumber = "•••• •••• •••• " + cardNumber

        if (
          retrievesAnOrderState.retrievesAnOrder !== undefined &&
          retrievesAnOrderState.retrievesAnOrder !== null
        ) {
          const services = getPayReject(retrievesAnOrderState.retrievesAnOrder)
          if (services?.length! > 0) {
            services?.map((sv) => {
              total += sv.prices.totalPrices[0].total.value
              setCurrencyCode(sv.prices.totalPrices[0].total.currencyCode)
            })
            setTotalPay(total)
          }
        }
        setCardNumber(cardNumber)
        setPnr(pnr)
      }
    }
  }

  return (
    <>
      <div className="p-4 lg:px-10 lg:pt-8 lg:pb-[30px] bg-white rounded shadow maximos-Dsk-strong">
        <div className="text-lg lg:text-3xl font-bold font-avianca text-avianca-black-2">
          {t("translation:Confirmation.estadoCompra.title")}
        </div>
        <div className="lg:flex mt-6 lg:mt-8">
          <div className="lg:w-3/12 font-normal font-avianca leading-normal mb-4 lg:mb-0">
            <div className="text-avianca-gray-5 text-sm lg:text-base">
              {t("translation:Confirmation.estadoCompra.cod")}
            </div>
            <div className="text-avianca-black-2 text-base font-bold lg:font-normal lg:text-4xl uppercase">
              {pnr}
            </div>
          </div>

          <div className="lg:w-3/12 font-normal font-avianca leading-normal mb-4 lg:mb-0">
            <div className="text-avianca-gray-5 text-sm lg:text-base">
              {t("translation:Confirmation.estadoCompra.status")}
            </div>
            <div className="items-center inline-flex">
              <img alt="" src={iconStatus} />
              <p
                className={`text-avianca-black-2 text-base ml-2 font-avianca font-bold lg:text-2xl 
                ${transactionStatus === "SUCCESS" ? "lg:font-normal" : ""}`}
              >
                {textStatus}
              </p>
            </div>
          </div>

          <div className="lg:w-4/12 font-normal font-avianca leading-normal mb-4 lg:mb-0">
            <div className="text-avianca-gray-5 text-sm lg:text-base">
              {t("translation:Confirmation.estadoCompra.method")}
            </div>
            <div className="items-center inline-flex">
              <img
                alt=""
                src={iconPay}
                className="w-[25px] h-4 lg:w-10 lg:h-[25px]"
              />
              <p className="text-avianca-black-2 text-base lg:text-2xl ml-2">
                {cardNumber}
              </p>
            </div>
          </div>

          <div className="lg:w-2/12 font-normal font-avianca leading-normal">
            <div className="text-avianca-gray-5 text-sm lg:text-base">
              {textPaid}
            </div>
            <div className="text-avianca-black-2 text-base font-bold lg:font-normal lg:text-2xl">
              {`$ ${new Intl.NumberFormat("es-CO").format(
                totalPay,
              )} ${currencyCode}`}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EstadoCompraComponent
