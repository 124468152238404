import useQueryStringValidator from "../hooks/useQueryStringValidator"
import TabsFlights from "../components/Tabs/TabsFlights"
import useHandleBounds from "../hooks/useHandleBounds"
import ManagementHeader from "../components/management/ManagementHeader"
import ManagementFooter from "../components/management/ManagementFooter"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { useNavigate } from "react-router"

const Management = () => {
  useQueryStringValidator()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    bounds,
    handleToggleCABG,
    isHasSameLuggage,
    setIsHasSameLuggage,
    handleRequestAddServices,
    currency,
    isActiveCheck,
    isHasRequestDeleteActive,
  } = useHandleBounds()

  useEffect(() => {
    if (bounds) {
      if (bounds.every((bound) => !bound.isActive)) {
        navigate("/information")
      }
    }
  }, [bounds, navigate])

  return (
    <>
      <div className="container py-5 px-4 md:p-0 flex flex-col">
        <div className="flex flex-col justify-center items-center md:mt-10 md:mb-6">
          <div className="bg-white max-w-[860px] w-full pt-5 pb-0 px-4 md:pt-6 md:pb-[10px] md:pr-10 md:pl-8 rounded shadow-md">
            <ManagementHeader isActiveCheck={isActiveCheck!} />
            {isActiveCheck ? (
              <div className="flex items-center md:hidden mt-[24px] mb-[20px]">
                <label
                  className="flex items-center font-avianca text-sm cursor-pointer"
                  htmlFor="checkResponsive"
                >
                  <input
                    className="appearance-none w-[20px] h-[20px] mr-1 bg-avianca-gray-1 border border-black rounded-sm checked:bg-icon-active checked:bg-cover checked:border-none "
                    type="checkbox"
                    id="checkResponsive"
                    checked={isHasSameLuggage}
                    onChange={() => setIsHasSameLuggage(!isHasSameLuggage)}
                  />
                  {t("translation:Management.sameBaggage")}
                </label>
              </div>
            ) : (
              ""
            )}
            {bounds ? (
              <TabsFlights
                bounds={bounds}
                handleToggleCABG={handleToggleCABG}
                isHasRequestDeleteActive={isHasRequestDeleteActive}
              />
            ) : (
              ""
            )}
          </div>
          {bounds ? (
            <ManagementFooter
              isHasSameLuggage={isHasSameLuggage!}
              setIsHasSameLuggage={setIsHasSameLuggage}
              handleRequestAddServices={handleRequestAddServices}
              bounds={bounds}
              currency={currency}
              isActiveCheck={isActiveCheck!}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  )
}

export default Management
