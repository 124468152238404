import { Fragment } from "react"
import { BoundTab } from "../../models/retrievesAnOrder"
import BodyTravelers from "./BodyTravelers"
import { HandleTabsBounds } from "../../models/tabsBounds"

const TabBodyFlight = ({
  bound,
  keyBound,
  handleTabsBounds,
  isHasRequestDeleteActive,
}: {
  bound: BoundTab
  keyBound: string
  handleTabsBounds: HandleTabsBounds
  isHasRequestDeleteActive: boolean
}) => {
  return (
    <Fragment>
      {bound.flights.map((flight, keyFlight) => {
        return (
          <Fragment key={flight.id}>
            {keyFlight === 0 ? (
              <BodyTravelers
                flight={flight}
                keyBound={keyBound}
                handleTabsBounds={handleTabsBounds}
                travelers={bound.travelers}
                serviceCABG={bound.serviceCABG}
                isActive={bound.isActive}
                isHasRequestDeleteActive={isHasRequestDeleteActive}
              />
            ) : (
              ""
            )}
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default TabBodyFlight
