import { useTranslation } from "react-i18next"
import airplane from "../../../assets/icons/airplane.svg"
import luggage from "../../../assets/icons/luggage.svg"
import takeoff from "../../../assets/icons/takeoff.svg"
import iconTakeoff from "../../../assets/icons/icon-takeoff.svg"
import { useAppSelector } from "../../../redux/hooks"
import { getRetrievesAnOrderState } from "../../../redux/retrievesAnOrder/retrievesAndOrderSlice"
import { useEffect, useState } from "react"
import { helperCapitalizeString } from "../../../utils/helpers/helperBounds"
import { useLocation } from "react-router-dom"
import { getPayReject } from "../../../utils/helpers/helperPayment"

const ResumenCompraComponent = ({
  transactionStatus,
}: {
  transactionStatus: string
}) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const retrievesAnOrderState = useAppSelector(getRetrievesAnOrderState)
  const [timeDepartureIda, setTimeDepartureIda] = useState("")
  const [timeArrivalIda, setTimeArrivalIda] = useState("")
  const [timeDepartureVuelta, setTimeDepartureVuelta] = useState("")
  const [timeArrivalVuelta, setTimeArrivalVuelta] = useState("")
  const [dateIda, setDateIda] = useState("")
  const [dateVuelta, setDateVuelta] = useState("")
  const [nameOrigen, setNameOrigen] = useState("")
  const [nameDestino, setNameDestino] = useState("")
  const [countOrigen, setCountOrigen] = useState(0)
  const [countDestino, setCountDestino] = useState(0)
  const [textCarryonOrigen, setTextCarryonOrigen] = useState("")
  const [textCarryonDestino, setTextCarryonDestino] = useState("")

  useEffect(() => {
    let origen = retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0].originLocationCode != undefined ? retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0].originLocationCode : ""
    let destino = retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0].destinationLocationCode != undefined ? retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0].destinationLocationCode : undefined
    let boundFlightsIda = retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0].flights != undefined ? retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0].flights : undefined
    let boundFlightsVuelta = retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[1] != undefined ? retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[1].flights : undefined
    let vuelos = retrievesAnOrderState.retrievesAnOrder?.dictionaries?.flight != undefined ? retrievesAnOrderState.retrievesAnOrder?.dictionaries?.flight : undefined
    let location = retrievesAnOrderState.retrievesAnOrder?.dictionaries?.location != undefined ? retrievesAnOrderState.retrievesAnOrder?.dictionaries?.location : undefined
    const services = retrievesAnOrderState.retrievesAnOrder?.data?.services
    let idioma = localStorage.getItem('i18nextLng')

    let totalOrigen = 0,
      totalDestino = 0

    if (origen !== undefined && origen != null && origen !== "") {

      if (location != undefined && location != null) {
        setNameOrigen(location[origen].cityName!)
      }

      if (boundFlightsIda?.length != undefined) {
        boundFlightsIda.map(bf => {
          if (vuelos != undefined && vuelos != null) {
            if (vuelos[bf.id].departure.locationCode === origen) {
              setDateIda(helperCapitalizeString(new Date(vuelos[bf.id].departure.dateTime).toLocaleDateString(idioma ? idioma : 'es', { weekday: "short", day: "numeric", month: "short", year: "numeric" })))
              setTimeDepartureIda(vuelos[bf.id].departure.dateTime.split("T")[1].slice(0, 5))

              if (state !== null) {
                const paymentRecords = retrievesAnOrderState.retrievesAnOrder?.data?.paymentRecords

                let data: any = state
                let { authorizationCode } = data

                if (transactionStatus === "SUCCESS") {
                  const paymentRecordsAuthorization = paymentRecords?.filter(pay => {
                    return pay.paymentTransactions[0]?.authorization?.approvalCode === authorizationCode
                  })

                  if (paymentRecordsAuthorization?.length! > 0) {
                    paymentRecordsAuthorization?.map(pra => {

                      const servicesFind = services?.find(sr => {
                        return sr.id === pra.serviceItemIds![0]
                      })

                      servicesFind?.flightIds.map(flight => {
                        if (flight === bf.id) {
                          totalOrigen += servicesFind.quantity
                        }
                      })
                    })
                  }
                } else if (transactionStatus === "REJECT") {
                  if (retrievesAnOrderState.retrievesAnOrder != undefined && retrievesAnOrderState.retrievesAnOrder != null) {
                    const services = getPayReject(retrievesAnOrderState.retrievesAnOrder)
                    services?.map(sv => {
                      sv?.flightIds.map(flight => {
                        if (flight === bf.id) {
                          totalOrigen += sv.quantity
                        }
                      })
                    })
                  }
                }
                setCountOrigen(totalOrigen)

                if (totalOrigen > 1) {
                  setTextCarryonOrigen(t("translation:Confirmation.resumenCompra.card.carry-on2"))
                } else {
                  setTextCarryonOrigen(t("translation:Confirmation.resumenCompra.card.carry-on1"))
                }
              }
            } if (vuelos[bf.id].arrival.locationCode === destino) {
              setTimeArrivalIda(vuelos[bf.id].arrival.dateTime.split("T")[1].slice(0, 5))
            }
          }
        })
      }
    }

    if (destino != undefined && destino != null && destino != "") {

      if (location != undefined && location != null) {
        setNameDestino(location[destino].cityName!)
      }

      if (boundFlightsVuelta?.length != undefined) {
        boundFlightsVuelta.map(bf => {
          if (vuelos != undefined && vuelos != null) {
            if (vuelos[bf.id].departure.locationCode === destino) {
              setDateVuelta(helperCapitalizeString(new Date(vuelos[bf.id].departure.dateTime).toLocaleDateString(idioma ? idioma : 'es', { weekday: "short", day: "numeric", month: "short", year: "numeric" })))
              setTimeDepartureVuelta(vuelos[bf.id].departure.dateTime.split("T")[1].slice(0, 5))

              if (state !== null) {
                const paymentRecords = retrievesAnOrderState.retrievesAnOrder?.data?.paymentRecords

                let data: any = state
                let { authorizationCode } = data

                if (transactionStatus === "SUCCESS") {
                  const paymentRecordsAuthorization = paymentRecords?.filter(pay => {
                    return pay.paymentTransactions[0]?.authorization?.approvalCode === authorizationCode
                  })

                  if (paymentRecordsAuthorization?.length! > 0) {
                    paymentRecordsAuthorization?.map(pra => {

                      const servicesFind = services?.find(sr => {
                        return sr.id === pra.serviceItemIds![0]
                      })

                      servicesFind?.flightIds.map(flight => {
                        if (flight === bf.id) {
                          totalDestino += servicesFind.quantity
                        }
                      })

                    })
                  }
                } else if (transactionStatus === "REJECT") {
                  if (retrievesAnOrderState.retrievesAnOrder != undefined && retrievesAnOrderState.retrievesAnOrder != null) {
                    const services = getPayReject(retrievesAnOrderState.retrievesAnOrder)
                    services?.map(sv => {
                      sv?.flightIds.map(flight => {
                        if (flight === bf.id) {
                          totalDestino += sv.quantity
                        }
                      })
                    })
                  }
                }
                setCountDestino(totalDestino)

                if (totalDestino > 1) {
                  setTextCarryonDestino(t("translation:Confirmation.resumenCompra.card.carry-on2"))
                } else {
                  setTextCarryonDestino(t("translation:Confirmation.resumenCompra.card.carry-on1"))
                }
              }
            } if (vuelos[bf.id].arrival.locationCode === origen) {
              setTimeArrivalVuelta(vuelos[bf.id].arrival.dateTime.split("T")[1].slice(0, 5))
            }
          }
        })
      }
    }
  }, [retrievesAnOrderState])

  return (
    <div className="bg-white rounded shadow my-6 p-4 lg:px-10 lg:py-8 maximos-Dsk-strong">
      <div className="text-lg lg:text-3xl font-bold font-avianca leading-loose text-avianca-black-2">
        {t("translation:Confirmation.resumenCompra.title")}
      </div>
      <div className="lg:flex lg:mt-8">
        <div
          className={`
            ${
              retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds
                .length !== undefined &&
              retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds.length >
                1
                ? "lg:w-1/2"
                : "lg:w-full"
            }
              rounded border border-neutral-200 px-3 py-4 lg:px-8 lg:py-6 lg:mb-0 text-avianca-black-1`}
        >
          <div className="flex lg:items-center lg:inline-flex font-avianca leading-loose">
            <img
              className="h-4 lg:h-6 mt-[2px] lg:mt-0 mr-2"
              src={takeoff}
              alt=""
            />
            <div className="lg:flex">
              <p className="text-sm lg:text-xl font-bold">
                {`${t(
                  "translation:Confirmation.resumenCompra.card.exit",
                )} ${helperCapitalizeString(nameOrigen)} ${t(
                  "translation:Confirmation.resumenCompra.card.to",
                )} ${helperCapitalizeString(nameDestino)}`}
              </p>
              <p className="hidden lg:block lg:mx-1"> - </p>
              <p className="text-xs lg:text-lg font-normal">{dateIda}</p>
            </div>
          </div>

          <div className="flex w-full justify-between mt-3 lg:mt-6">
            <div className="w-auto flex-col justify-center items-start font-avianca mr-2">
              <p className="text-sm lg:text-xl font-bold leading-[23px]">
                {timeDepartureIda}
              </p>
              <p className="text-xs lg:text-lg font-normal leading-normal">
                {
                  retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0]
                    .originLocationCode
                }
              </p>
            </div>

            <div className="flex items-center grow">
              <hr className="grow border border-t-avianca-gray-6" />
              <img src={airplane} className="h-5 lg:h-8 mx-1" alt="" />
              <hr className="grow border border-t-avianca-gray-6" />
            </div>

            <div className="w-auto flex-col justify-center items-end inline-flex font-avianca ml-2">
              <p className="text-right text-sm lg:text-xl font-bold leading-[23px]">
                {timeArrivalIda}
              </p>
              <p className="text-right text-xs lg:text-lg font-normal leading-normal">
                {
                  retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0]
                    .destinationLocationCode
                }
              </p>
            </div>
          </div>

          <div className="bg-teal-100 bg-opacity-50 rounded border border-cyan-600 border-opacity-50 mt-4 lg:mt-5">
            <div className="text-sm font-avianca leading-none items-center p-3 lg:p-4 lg:inline-flex">
              <p className="font-bold">
                {t("translation:Confirmation.resumenCompra.card.textPurchased")}
              </p>
              <div className="inline-flex items-center mt-1 lg:mt-0">
                <img
                  src={luggage}
                  className="h-5 w-5 mx-[2px] lg:h-6 lg:w-6 lg:mx-1"
                  alt=""
                />
                <p className="text-xs md:text-sm font-normal">
                  {`${countOrigen} ${textCarryonOrigen}`}
                </p>
              </div>
            </div>
          </div>
        </div>

        {retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds.length !==
          undefined &&
          retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds.length >
            1 && (
            <div className="lg:w-1/2 rounded border border-neutral-200 px-3 py-4 lg:px-8 lg:py-6 lg:mb-0 lg:ml-8 text-avianca-black-1 mt-4 lg:mt-0">
              <div className="flex lg:items-center lg:inline-flex font-avianca leading-loose">
                <img
                  className="h-4 lg:h-6 mt-[2px] lg:mt-0 mr-2"
                  src={iconTakeoff}
                  alt=""
                />
                <div className="lg:flex">
                  <p className="text-sm lg:text-xl font-bold">
                    {`${t(
                      "translation:Confirmation.resumenCompra.card.return",
                    )} ${helperCapitalizeString(nameDestino)} ${t(
                      "translation:Confirmation.resumenCompra.card.to",
                    )} ${helperCapitalizeString(nameOrigen)}`}
                  </p>
                  <p className="hidden lg:block lg:mx-1"> - </p>
                  <p className="text-xs lg:text-lg font-normal">{dateVuelta}</p>
                </div>
              </div>

              <div className="flex w-full justify-between mt-3 lg:mt-6">
                <div className="w-auto flex-col justify-center items-start font-avianca mr-2">
                  <p className="text-sm lg:text-xl font-bold leading-[23px]">
                    {timeDepartureVuelta}
                  </p>
                  <p className="text-xs lg:text-lg font-normal leading-normal">
                    {
                      retrievesAnOrderState.retrievesAnOrder?.data?.air
                        .bounds[0].destinationLocationCode
                    }
                  </p>
                </div>

                <div className="flex items-center grow">
                  <hr className="grow border border-t-avianca-gray-6" />
                  <img src={airplane} className="h-5 lg:h-8 mx-1" alt="" />
                  <hr className="grow border border-t-avianca-gray-6" />
                </div>

                <div className="w-auto flex-col justify-center items-end inline-flex font-avianca ml-2">
                  <p className="text-right text-sm lg:text-xl font-bold leading-[23px]">
                    {timeArrivalVuelta}
                  </p>
                  <p className="text-right text-xs lg:text-lg font-normal leading-normal">
                    {
                      retrievesAnOrderState.retrievesAnOrder?.data?.air
                        .bounds[0].originLocationCode
                    }
                  </p>
                </div>
              </div>

              <div className="bg-teal-100 bg-opacity-50 rounded border border-cyan-600 border-opacity-50 mt-4 lg:mt-5">
                <div className="text-sm font-avianca leading-none items-center p-3 lg:p-4 lg:inline-flex">
                  <p className="font-bold">
                    {t(
                      "translation:Confirmation.resumenCompra.card.textPurchased",
                    )}
                  </p>
                  <div className="inline-flex items-center mt-1 lg:mt-0">
                    <img
                      src={luggage}
                      className="h-5 w-5 mx-[2px] lg:h-6 lg:w-6 lg:mx-1"
                      alt=""
                    />
                    <p className="text-xs md:text-sm font-normal">
                      {countDestino} {textCarryonDestino}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  )
}

export default ResumenCompraComponent
