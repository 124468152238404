import { Fragment } from "react"
import { BoundTab } from "../../models/retrievesAnOrder"
import TabHeaderFlight from "./TabHeaderFlight"
import { HandleTabsBounds } from "../../models/tabsBounds"

const TabsHeaderFlights = ({
  bounds,
  handleTabsBounds,
}: {
  bounds: BoundTab[]
  handleTabsBounds: HandleTabsBounds
}) => {
  return (
    <>
      {bounds.map((bound) => {
        return (
          <Fragment key={bound.airBoundId}>
            <TabHeaderFlight
              bound={bound}
              keyBound={bound.airBoundId}
              handleTabsBounds={handleTabsBounds}
            />
          </Fragment>
        )
      })}
    </>
  )
}

export default TabsHeaderFlights
