import { Fragment, useEffect, useState } from "react"
import { Base, TravelerTab } from "../../models/retrievesAnOrder"
import iconPerson from "../../assets/icons/icon-person.png"
import iconLuggage from "../../assets/icons/icon-luggage.png"
import { Price, Service } from "../../models/catalogueType"
import ButtonCardTraveler from "./ButtonCardTraveler"
import DetailLuggageCardTraveler from "./DetailLuggageCardTraveler"

const CardTraveler = ({
  traveler,
  keyBound,
  handleToggleCABG,
  serviceCABG,
  lastPassenger,
  isHasRequestDeleteActive,
}: {
  traveler: TravelerTab
  keyBound: string
  handleToggleCABG: Function
  serviceCABG: Service | undefined
  lastPassenger: boolean
  isHasRequestDeleteActive: boolean
}) => {
  const [total, setTotal] = useState<Base>()
  const [totalService, setTotalService] = useState<Price>()

  useEffect(() => {
    if (traveler.service) {
      setTotal(traveler.service?.service?.prices.unitPrices[0].prices[0].total)
    }
  }, [traveler])

  useEffect(() => {
    if (!traveler.service?.service) {
      const priceService = serviceCABG?.prices?.unitPrices.find((price) => {
        return price.travelerIds.find((priceTraveler) => {
          return JSON.stringify(priceTraveler).includes(traveler.id)
        })
      })
      if (priceService?.prices) {
        setTotalService(priceService.prices[0])
      }
    }
  }, [serviceCABG, traveler])

  return (
    <Fragment>
      <>
        <div
          className={`py-6 md:pt-10 md:pb-8 md:px-2 ${
            !lastPassenger ? "border-b border-avianca-gray-2" : ""
          }`}
          role="tabpanel"
        >
          <div className="flex flex-col">
            <div className="flex justify-start items-center">
              <img
                className="w-[20px] h-[20px] md:w-[24px] md:h-[24px]"
                src={iconPerson}
                alt="icon person"
              />
              <p className="mx-2 md:mx-3 font-avianca-bold text-avianca-black-2 text-md md:text-xl">
                {traveler.names[0].firstName} {traveler.names[0].lastName}
              </p>
            </div>
            <div className="mt-5 md:mt-6 md:mx-5 flex flex-wrap justify-between items-center">
              <div className="flex md:flex-wrap items-start">
                <img
                  className="w-[20px] h-[20px] md:w-[32px] md:h-[32px] md:mx-1 mt-1"
                  src={iconLuggage}
                  alt="icon luggage"
                />
                <DetailLuggageCardTraveler
                  total={total}
                  totalService={totalService}
                  traveler={traveler}
                />
              </div>
              {!traveler.service?.isPaid ? (
                <ButtonCardTraveler
                  traveler={traveler}
                  handleToggleCABG={handleToggleCABG}
                  keyBound={keyBound}
                  isHasRequestDeleteActive={isHasRequestDeleteActive}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    </Fragment>
  )
}

export default CardTraveler
