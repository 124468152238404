import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Params } from "../../models/general"
import { RootState } from "../store"

export interface ParamsUrlState {
  paramsUrl?: Params
  hasParams: boolean
}

const initialState: ParamsUrlState = {
  hasParams: false,
  paramsUrl: {
    lang: "es",
  },
}

export const paramsUrlSlice = createSlice({
  name: "paramsUrlState",
  initialState,
  reducers: {
    setParamsUrlState: (state, action: PayloadAction<Params>) => {
      const newState = {
        ...state,
        paramsUrl: action.payload,
        hasParams: true,
      }
      return newState
    },
    setParamsUrl: (state, action: PayloadAction<Params>) => {
      const newParams = {
        ...state.paramsUrl,
        lang: action.payload.lang,
      }
      return { ...state, paramsUrl: newParams }
    },
  },
})

export const getParamsUrl = (state: RootState) => state.paramsUrl

export const { setParamsUrlState, setParamsUrl } = paramsUrlSlice.actions
export default paramsUrlSlice.reducer
