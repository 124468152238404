import { useTranslation } from "react-i18next"
import imageLuggage from "../../assets/images/imageLuggage.png"

const WithLuggageTab = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="flex flex-col items-center mt-8 md:mt-12">
        <img
          className="max-w-[114px] md:max-w-[172px] md:max-h-[177px]"
          src={imageLuggage}
          alt="luggage"
        />
        <h4 className="mt-4 md:mt-9 mb-8 md:mb-[52px] font-avianca-bold text-center text-base md:text-xl">
          {t("translation:Management.yourRateAlready")}
        </h4>
      </div>
    </>
  )
}

export default WithLuggageTab
