import { useState } from "react"
import { useTranslation } from "react-i18next"
import { TravelerTab } from "../../models/retrievesAnOrder"
import iconDelete from "../../assets/icons/icon-delete.svg"
import gifLoader from "../../assets/gif/git-loader.gif"
import iconDeleteResponsive from "../../assets/icons/icon-delete-responsive.svg"

const ButtonCardTraveler = ({
  traveler,
  handleToggleCABG,
  keyBound,
  isHasRequestDeleteActive,
}: {
  traveler: TravelerTab
  handleToggleCABG: Function
  keyBound: string
  isHasRequestDeleteActive: boolean
}) => {
  const { t } = useTranslation()
  const handleButton = () => {
    handleToggleCABG({ keyBound, traveler })
  }

  const handleA = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    await handleToggleCABG({ keyBound, traveler })
  }

  return (
    <>
      {!traveler.service?.hasAddLuggage && !traveler.service?.isPaid ? (
        <>
          <button
            className="hidden md:block border-2 rounded px-6 py-2 font-avianca-bold border-avianca-black-2 hover:bg-avianca-gray-3 active:bg-avianca-black-2 active:text-white"
            onClick={handleButton}
          >
            {t("translation:Management.addLuggage")}
          </button>
          <button
            className="md:hidden w-[89px] h-[32px] border-2 rounded text-sm font-avianca-bold border-avianca-black-2 hover:bg-avianca-gray-3 active:bg-avianca-black-2 active:text-white"
            onClick={handleButton}
          >
            {t("translation:Management.addLuggageResponsive")}
          </button>
        </>
      ) : (
        ""
      )}
      {traveler.service?.hasAddLuggage && !traveler.service?.isPaid ? (
        <>
          {traveler?.isLoader ? (
            <div className="flex flex-wrap justify-center items-center md:h-[44px] md:w-[170px]">
              <div className="bg-white flex justify-center items-center">
                <img
                  className="h-[35px] md:h-[42px]"
                  src={gifLoader}
                  alt="Gif loader"
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap justify-center items-center">
              <img
                src={iconDeleteResponsive}
                alt="Icon delete"
                className="mx-1 md:hidden"
              />
              <img
                src={iconDelete}
                alt="Icon delete"
                className="hidden md:block md:mx-2"
              />
              <a
                href="/#"
                className={`hidden md:block font-avianca text-base text-avianca-blue-4 border-b border-avianca-blue-4 hover:font-avianca-bold hover:border-b-2 active:font-avianca-bold active:border-b-2 ${
                  isHasRequestDeleteActive
                    ? "pointer-events-none cursor-default"
                    : ""
                }`}
                onClick={handleA}
              >
                {t("translation:Management.eliminateLuggage")}
              </a>
              <a
                href="/#"
                className={`md:hidden font-avianca text-sm text-avianca-blue-4 border-b border-avianca-blue-4 hover:font-avianca-bold hover:border-b-2 active:font-avianca-bold active:border-b-2 ${
                  isHasRequestDeleteActive
                    ? "pointer-events-none cursor-default"
                    : ""
                }`}
                onClick={handleA}
              >
                {t("translation:Management.eliminateLuggageResponsive")}
              </a>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default ButtonCardTraveler
