import { Fragment, useState, useEffect } from "react"
import { HandleTabsBounds } from "../../models/tabsBounds"
import { FlightElement, TravelerTab } from "../../models/retrievesAnOrder"
import CardTraveler from "./CardTraveler"
import WithLuggageTab from "../management/WithLuggageTab"
import { Service } from "../../models/catalogueType"

const BodyTravelers = ({
  flight,
  keyBound,
  handleTabsBounds,
  travelers,
  serviceCABG,
  isActive,
  isHasRequestDeleteActive,
}: {
  flight: FlightElement
  keyBound: string
  handleTabsBounds: HandleTabsBounds
  travelers: TravelerTab[] | undefined
  serviceCABG: Service | undefined
  isActive: boolean
  isHasRequestDeleteActive: boolean
}) => {
  const [activeTab, setActiveTab] = useState(false)
  const [cantTraveler, setCantTraveler] = useState(0)

  useEffect(() => {
    if (keyBound === handleTabsBounds.tabSelected) {
      setActiveTab(true)
    } else {
      setActiveTab(false)
    }
  }, [keyBound, handleTabsBounds.tabSelected])

  useEffect(() => {
    if (travelers) {
      const countTravelers = travelers.filter((traveler: TravelerTab) => {
        return traveler.passengerTypeCode === "INF"
      })
      setCantTraveler(countTravelers.length)
    }
  }, [travelers])

  return (
    <Fragment>
      {activeTab ? (
        <>
          {travelers !== undefined &&
          isActive &&
          (flight.fareFamilyCode === "XS" ||
            flight.fareFamilyCode === "BASIC") ? (
            travelers.map((traveler, index) => {
              const lastPassenger =
                travelers.length - cantTraveler === index + 1 ? true : false
              if (traveler.passengerTypeCode !== "INF") {
                return (
                  <CardTraveler
                    key={traveler.id}
                    traveler={traveler}
                    keyBound={keyBound}
                    handleToggleCABG={handleTabsBounds.handleToggleCABG}
                    serviceCABG={serviceCABG}
                    lastPassenger={lastPassenger}
                    isHasRequestDeleteActive={isHasRequestDeleteActive}
                  />
                )
              }
              return ""
            })
          ) : (
            <WithLuggageTab />
          )}
        </>
      ) : (
        ""
      )}
    </Fragment>
  )
}

export default BodyTravelers
