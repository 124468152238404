import {
  PayURequest,
  PayUResponse,
  ViancaPayAuthorizationRequest,
  ViancaPayAuthorizationResponse,
  ViancaPayPaymentRequest,
  ViancaPayPaymentResponse,
} from "../models/payment"
import { makePostRequest } from "../utils/HttpAdapterAxios"
import { structureRequests } from "./headers"

export const postTokenPayU = async (
  body: PayURequest,
): Promise<PayUResponse | null> => {
  const url = `${structureRequests.baseURLViancaPay}createTokenPayU`
  return await makePostRequest<PayUResponse | null>(
    url,
    body,
    structureRequests.headersViancaPay,
  )
}

export const postViancaPayAuthorization = async (
  body: ViancaPayAuthorizationRequest,
): Promise<ViancaPayAuthorizationResponse | null> => {
  const url = `${structureRequests.baseURLViancaPay}autorizacion`
  return await makePostRequest<ViancaPayAuthorizationResponse | null>(
    url,
    body,
    structureRequests.headersViancaPay,
  )
}

export const postViancaPayPayment = async (
  body: ViancaPayPaymentRequest,
  token: string,
): Promise<ViancaPayPaymentResponse | null> => {
  const url = `${structureRequests.baseURLViancaPay}Servicing`
  structureRequests.headersViancaPayWithToken.headers.Authorization = token
  return await makePostRequest<ViancaPayPaymentResponse | null>(
    url,
    body,
    structureRequests.headersViancaPayWithToken,
  )
}
