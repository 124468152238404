import { useAppSelector } from "../redux/hooks"
import { getRetrievesAnOrderState } from "../redux/retrievesAnOrder/retrievesAndOrderSlice"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import useQueryStringValidator from "../hooks/useQueryStringValidator"
import EstadoCompraComponent from "../components/confirmacion/estado_compra/EstadoCompraComponent"
import AccordionComponent from "../components/confirmacion/accordion/AccordionComponent"
import ResumenCompraComponent from "../components/confirmacion/resumen_compra/ResumenCompraComponent"
import headerConfirmacion from "../assets/images/headerConfirmacion.svg"

const Confirmation = () => {
  useQueryStringValidator()
  const { t } = useTranslation()
  const { state } = useLocation()
  const retrievesAnOrderState = useAppSelector(getRetrievesAnOrderState)
  const [transactionStatus, setTransactionStatus] = useState("")
  const [title, setTitle] = useState("")

  useEffect(() => {
    if (state !== null) {
      let data: any = state
      let { status } = data
      if (
        status === "REJECT" ||
        (status === "SUCCESS" && data.statusResponse === "DECLINED")
      ) {
        setTransactionStatus("REJECT")
        setTitle("translation:Confirmation.banner.titleReject")
      }
      if (status === "SUCCESS" && data.statusResponse === "APPROVED") {
        setTransactionStatus("SUCCESS")
        setTitle("translation:Confirmation.banner.titleSucces")
      }
    }
  }, [state])

  return (
    <div className="block w-full">
      <div className="w-full relative flex justify-center">
        <img
          className="w-full h-[160px] lg:h-auto"
          alt=""
          src={headerConfirmacion}
        />
        <div className="text-white font-avianca absolute top-0 h-full w-full container flex flex-col justify-center p-4 md:p-0">
          <p className="text-lg md:text-2xl font-normal leading-normal">
            {`¡${t("translation:Confirmation.banner.hello")} ${
              retrievesAnOrderState.retrievesAnOrder?.data?.travelers[0]
                .names[0].firstName
            } ${
              retrievesAnOrderState.retrievesAnOrder?.data?.travelers[0]
                .names[0].lastName
            }!
            `}
          </p>
          <p className="text-[26px] md:text-[40px] font-bold">{t(title)}</p>
        </div>
      </div>

      <div className="px-4 lg:px-[104px] my-10 maximos-Dsk-strong-page">
        <EstadoCompraComponent transactionStatus={transactionStatus} />
        <ResumenCompraComponent transactionStatus={transactionStatus} />
        <AccordionComponent transactionStatus={transactionStatus} />
      </div>
    </div>
  )
}

export default Confirmation
