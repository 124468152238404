import { useCallback, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { getParamsUrl } from "../redux/paramsUrl/paramsUrlSlice"
import {
  getRetrievesAnOrderState,
  setRetrievesAnOrderStateAsync,
} from "../redux/retrievesAnOrder/retrievesAndOrderSlice"
import { PnrRequest } from "../models/general"
import useHandleParams from "./useHandleParams"
import { useNavigate } from "react-router"
import {
  getCatalogueTypeState,
  setCatalogueTypeStateAsync,
} from "../redux/catalogueType/catalogueTypeSlice"

const useQueryStringValidator = () => {
  const dispatch = useAppDispatch()
  const { hasParams, paramsUrl } = useAppSelector(getParamsUrl)
  const { retrievesAnOrder } = useAppSelector(getRetrievesAnOrderState)
  const { catalogueType } = useAppSelector(getCatalogueTypeState)
  const { handleSetlang } = useHandleParams()
  const [confirmParams, setConfirmParams] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const handleConfirmParams = () => {
      const confirmParams = handleSetlang()
      setConfirmParams(confirmParams)
    }
    handleConfirmParams()
  }, [handleSetlang])

  const handleRedirect = useCallback(() => {
    if (hasParams && paramsUrl) {
      const pnr: PnrRequest = {
        recLoc: paramsUrl.recLoc!,
        lastName: paramsUrl.lastName!,
      }
      dispatch(setRetrievesAnOrderStateAsync(pnr))
      dispatch(setCatalogueTypeStateAsync(pnr))
    } else {
      navigate("/error", { state: { paramsUrl } })
    }
  }, [dispatch, navigate, hasParams, paramsUrl])

  useEffect(() => {
    if (confirmParams) {
      handleRedirect()
    }
  }, [confirmParams, handleRedirect])

  useEffect(() => {
    if (retrievesAnOrder?.errors) {
      const hasError = retrievesAnOrder.errors.find((error) => error.code)
      if (hasError) navigate("/error", { state: { paramsUrl } })
    }
    if (catalogueType?.errors || catalogueType?.warnings) {
      navigate("/error", { state: { paramsUrl } })
    }
  }, [
    retrievesAnOrder?.errors,
    catalogueType?.errors,
    catalogueType?.warnings,
    navigate,
    paramsUrl,
  ])

  useEffect(() => {
    if (retrievesAnOrder?.data) {
      const hasFamilyXS = retrievesAnOrder.data.air.bounds.filter((bound) => {
        return bound.flights.find((flight) => {
          return (
            flight.fareFamilyCode === "XS" || flight.fareFamilyCode === "BASIC"
          )
        })
      })
      if (!hasFamilyXS.length) navigate("/information")
    }
  }, [retrievesAnOrder?.data, navigate])
}

export default useQueryStringValidator
