import takeoff from "../../../assets/icons/takeoff.svg"
import iconTakeoff from "../../../assets/icons/icon-takeoff.svg"
import airplane from "../../../assets/icons/airplane.svg"
import { useAppSelector } from "../../../redux/hooks"
import { getRetrievesAnOrderState } from "../../../redux/retrievesAnOrder/retrievesAndOrderSlice"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getGrandTotalAmount } from "../../../utils/helpers/helperPayment"

const ResumenCompraInfo = ({ currency }: { currency: string }) => {
  const { t } = useTranslation()
  const retrievesAnOrderState = useAppSelector(getRetrievesAnOrderState)
  const [timeDepartureIda, setTimeDepartureIda] = useState("")
  const [timeArrivalIda, setTimeArrivalIda] = useState("")
  const [timeDepartureVuelta, setTimeDepartureVuelta] = useState("")
  const [timeArrivalVuelta, setTimeArrivalVuelta] = useState("")
  const [dateIda, setDateIda] = useState("")
  const [dateVuelta, setDateVuelta] = useState("")

  useEffect(() => {
    let origen =
      retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0]
        .originLocationCode
    let destino =
      retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0]
        .destinationLocationCode
    let boundFlightsIda =
      retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0].flights
    let boundFlightsVuelta =
      retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[1] !== undefined
        ? retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[1].flights
        : undefined
    let vuelos = retrievesAnOrderState.retrievesAnOrder?.dictionaries?.flight
    let idioma = localStorage.getItem("i18nextLng")

    if (origen !== undefined && origen !== null && origen !== "") {
      if (boundFlightsIda?.length !== undefined) {
        boundFlightsIda.map((bf) => {
          if (vuelos !== undefined && vuelos != null) {
            if (vuelos[bf.id].departure.locationCode === origen) {
              setDateIda(
                new Date(vuelos[bf.id].departure.dateTime).toLocaleDateString(
                  idioma ? idioma : "es",
                  { weekday: "short", day: "numeric", month: "short" },
                ),
              )
              setTimeDepartureIda(
                vuelos[bf.id].departure.dateTime.split("T")[1].slice(0, 5),
              )
            }
            if (vuelos[bf.id].arrival.locationCode === destino) {
              setTimeArrivalIda(
                vuelos[bf.id].arrival.dateTime.split("T")[1].slice(0, 5),
              )
            }
          }
        })
      }
    }

    if (destino !== undefined && destino != null && destino !== "") {
      if (boundFlightsVuelta?.length !== undefined) {
        boundFlightsVuelta.map((bf) => {
          if (vuelos !== undefined && vuelos != null) {
            if (vuelos[bf.id].departure.locationCode === destino) {
              setDateVuelta(
                new Date(vuelos[bf.id].departure.dateTime).toLocaleDateString(
                  idioma ? idioma : "es",
                  { weekday: "short", day: "numeric", month: "short" },
                ),
              )
              setTimeDepartureVuelta(
                vuelos[bf.id].departure.dateTime.split("T")[1].slice(0, 5),
              )
            }
            if (vuelos[bf.id].arrival.locationCode === origen) {
              setTimeArrivalVuelta(
                vuelos[bf.id].arrival.dateTime.split("T")[1].slice(0, 5),
              )
            }
          }
        })
      }
    }
  }, [retrievesAnOrderState])

  return (
    <div className="pt-6 md:pt-8 md:bg-white md:rounded-[3px] md:shadow md:border">
      <h3 className="px-10 md:px-[34px] text-lg font-bold font-avianca leading-normal md:text-2xl md:leading-8 text-avianca-black-1">
        {t("translation:Payment.banner.title")}
      </h3>
      <div className="mt-[17px] mb-[19] px-10 md:mt-6 md:mb-0 md:px-[34px] text-avianca-black-1">
        <div className="text-sm font-normal font-avianca leading-[17.69px] md:text-xl md:leading-normal">
          {t("translation:Payment.banner.payTotal")}
        </div>
        <div className="text-lg font-bold font-avianca leading-normal md:text-2xl md:leading-8 md:mt-1">
          {`$${new Intl.NumberFormat("es-CO").format(
            retrievesAnOrderState.retrievesAnOrder !== undefined
              ? getGrandTotalAmount(retrievesAnOrderState.retrievesAnOrder!)
              : 0,
          )} ${currency}`}
        </div>
      </div>
      <div className="border border-neutral-200 mt-[19px] md-mt-[26px] md:mb-[22px]" />

      <div className="mt-[17px]">
        <div className="px-10 md:px-8 text-lg font-bold font-avianca leading-8 md:text-2xl text-avianca-black-1">
          {t("translation:Payment.banner.selection")}
        </div>

        <div className="mt-2 mb-[23px] md:mt-4 md:mb-0 px-10 md:px-8">
          <div className="items-center inline-flex font-avianca leading-loose">
            <img
              className="float-left md:float-none w-[18px] md:w-[24px]"
              src={takeoff}
              alt=""
            />
            <p className="ml-[6px] text-sm font-normal font-avianca leading-[17.69px] text-avianca-black-1 md:text-lg md:leading-normal md:ml-2">
              {t("translation:Payment.banner.exit")} | {dateIda}
            </p>
          </div>
          <div className="flex justify-start items-start md:mt-[7px] md:mb-8">
            <div className="w-16 self-stretch font-avianca">
              <p className="text-[22px] font-bold font-avianca leading-[30px] md:leading-10 text-avianca-black-1 md:text-2xl">
                {
                  retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0]
                    .originLocationCode
                }
              </p>
              <p className="text-xs font-normal font-avianca leading-[14.74px]  text-avianca-gray-5 md:text-lg">
                {timeDepartureIda}
              </p>
            </div>
            <img
              src={airplane}
              className="md:h-6 md:mt-2 w-[18px] md:w-[24px] mt-1 md:mx-6"
              alt=""
            />
            <div className="w-16 self-stretch font-avianca ml-3">
              <p className="text-[22px] font-bold font-avianca leading-[30px] md:leading-10 text-avianca-black-1 md:text-2xl">
                {
                  retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds[0]
                    .destinationLocationCode
                }
              </p>
              <p className="text-xs font-normal font-avianca leading-[14.74px] md:text-lg md:leading-tight  text-avianca-gray-5">
                {timeArrivalIda}
              </p>
            </div>
          </div>
        </div>
        {retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds.length !==
          undefined &&
        retrievesAnOrderState.retrievesAnOrder?.data?.air.bounds.length > 1 ? (
          <>
            <div className="h-px border border-neutral-200 mb-[23px] mx-10 md:my-8 " />
            <div className="px-10 md:px-8">
              <div className="items-center inline-flex font-avianca leading-loose">
                <img
                  className="float-left md:float-none w-[18px] md:w-[24px]"
                  src={iconTakeoff}
                  alt=""
                />
                <p className="text-sm font-normal font-avianca leading-[17.69px] ml-[6px] md:text-lg md:leading-normal md:ml-2 text-avianca-black-1">
                  {t("translation:Payment.banner.return")} | {dateVuelta}
                </p>
              </div>
              <div className="flex justify-start items-start md:mt-[7px] md:mb-8">
                <div className="w-16 self-stretch font-avianca">
                  <p className="text-[22px] font-bold font-avianca leading-[30px] md:leading-10 text-avianca-black-1 md:text-2xl">
                    {
                      retrievesAnOrderState.retrievesAnOrder?.data?.air
                        .bounds[1]?.originLocationCode
                    }
                  </p>
                  <p className="text-xs font-normal font-avianca leading-[14.74px] md:text-lg md:leading-tight text-avianca-gray-5">
                    {timeDepartureVuelta}
                  </p>
                </div>
                <img
                  src={airplane}
                  className="md:h-6 md:mt-2 w-[18px] md:w-[24px] mt-1 md:mx-6"
                  alt=""
                />
                <div className="w-16 self-stretch font-avianca ml-3 mb-[24px] md:mb-0">
                  <p className="text-[22px] font-bold font-avianca leading-[30px] md:leading-10 text-avianca-black-1 md:text-2xl">
                    {
                      retrievesAnOrderState.retrievesAnOrder?.data?.air
                        .bounds[1]?.destinationLocationCode
                    }
                  </p>
                  <p className="text-xs font-normal font-avianca leading-[14.74px] md:text-lg md:leading-tight  text-avianca-gray-5">
                    {timeArrivalVuelta}
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default ResumenCompraInfo
