import "./header.css"
import LangSelect from "../langSelect.tsx/LangSelect"

const Header = ({ isPageStatic }: { isPageStatic: boolean }) => {
  return (
    <>
      <header
        className={`bg-avianca-red-1 min-h-[50px] md:min-h-[72px] flex items-center shrink-0  ${
          isPageStatic ? "tallInitial:static fixed top-0 w-full" : ""
        }`}
      >
        <div className="container p-3 flex justify-between md:justify-start mx-auto">
          <div className="logo-avianca md:mr-4"></div>
          <LangSelect />
        </div>
      </header>
    </>
  )
}

export default Header
