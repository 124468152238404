export const Footer = ({ isPageStatic }: { isPageStatic: boolean }) => {
  return (
    <footer
      className={`
      ${
        isPageStatic ? "fixed bottom-0 w-full" : ""
      } bg-white h-[48px] flex justify-center shrink-0`}
    >
      <div className="container p-3 flex justify-center items-center">
        <p className="text-avianca-gray-5 font-avianca font-normal text-xs">
          {`© Avianca S.A ${new Date().getFullYear()}`}
        </p>
      </div>
    </footer>
  )
}

export default Footer
