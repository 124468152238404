import { PassengerDetail } from "../../models/general"
import {
  PaymentRecord,
  RetrievesAnOrder,
  Service,
  Traveler,
} from "../../models/retrievesAnOrder"
import { getPayReject } from "./helperPayment"

const handleWithPaymentRecordsForServices = (
  paymentRecordsAuthorization: PaymentRecord[],
  services: Service[],
  travelers: Traveler[],
) => {
  return paymentRecordsAuthorization.map((paymentRecord) => {
    const service = services?.find((service) => {
      return service.id === paymentRecord.serviceItemIds![0]
    })
    const traveler = getTraveler(travelers, service!)
    const passengerDetail: PassengerDetail = {
      nameComplete:
        `${traveler?.names[0].firstName} ${traveler?.names[0].lastName}`!,
      count: service!.quantity,
      value: service!.prices.unitPrices[0].prices[0].total.value,
      id: traveler?.id!,
    }
    return passengerDetail
  })
}

const handleWithoutPaymentRecordsForServices = (
  services: Service[],
  travelers: Traveler[],
) => {
  return services.map((service) => {
    const traveler = getTraveler(travelers, service)
    const passengerDetail: PassengerDetail = {
      nameComplete:
        `${traveler?.names[0].firstName} ${traveler?.names[0].lastName}`!,
      count: service.quantity,
      value: service.prices.unitPrices[0].prices[0].total.value,
      id: traveler?.id!,
    }
    return passengerDetail
  })
}

const getTraveler = (travelers: Traveler[], service: Service) => {
  return travelers?.find((traveler) => {
    return traveler.id === service?.travelerId
  })
}

const handlePassengerDetail = (groupServicesForTraveler: PassengerDetail[]) => {
  return groupServicesForTraveler.reduce((accumulator, currentValue) => {
    if (
      accumulator?.count &&
      accumulator?.value &&
      currentValue?.count &&
      currentValue?.value
    ) {
      const count = currentValue?.count + accumulator?.count
      const value = currentValue?.value + accumulator?.value
      return {
        ...accumulator,
        count,
        value,
      }
    }
    return currentValue
  })
}

const handleServicesForTraveler = (
  travelers: Traveler[],
  paymentRecordsForServices: PassengerDetail[],
) => {
  const travelersGroup = travelers.map((traveler) => {
    return paymentRecordsForServices.filter((paymentRecordForService) => {
      return paymentRecordForService?.id === traveler.id
    })
  })
  return travelersGroup.filter((travelerGroup) => travelerGroup.length)
}

export const getPurchaseStatus = (
  retrievesAnOrder: RetrievesAnOrder,
  authorizationCode: string,
  status: string,
) => {
  const services = retrievesAnOrder?.data?.services
  const travelers = retrievesAnOrder?.data?.travelers
  const paymentRecords = retrievesAnOrder?.data?.paymentRecords
  if (paymentRecords && status === "SUCCESS") {
    const paymentRecordsAuthorization = paymentRecords.filter(
      (paymentRecord) => {
        return (
          paymentRecord.paymentTransactions[0]?.authorization?.approvalCode ===
          authorizationCode
        )
      },
    )
    if (paymentRecordsAuthorization && services && travelers) {
      const paymentRecordsForServices = handleWithPaymentRecordsForServices(
        paymentRecordsAuthorization,
        services,
        travelers,
      )
      const servicesForTraveler = handleServicesForTraveler(
        travelers,
        paymentRecordsForServices,
      )
      return servicesForTraveler.map((servicesForTraveler) => {
        return handlePassengerDetail(servicesForTraveler)
      })
    }
  }
  if (status === "REJECT") {
    const services = getPayReject(retrievesAnOrder)
    if (services && travelers) {
      const withoutPaymentRecordsForServices =
        handleWithoutPaymentRecordsForServices(services, travelers)
      const servicesForTraveler = handleServicesForTraveler(
        travelers,
        withoutPaymentRecordsForServices,
      )
      return servicesForTraveler.map((servicesForTraveler) => {
        return handlePassengerDetail(servicesForTraveler)
      })
    }
  }
  return []
}
