import { Card, OrderPayment, PaymentTokens } from "../models/payment"
import { useAppSelector } from "../redux/hooks"
import { getRetrievesAnOrderState } from "../redux/retrievesAnOrder/retrievesAndOrderSlice"
import {
  getAESEncrypt,
  getDeviceSessionId,
  getEnvironmentType,
  getIpNavigation,
  getOrganizationId,
  getTokenPayU,
  getViancaPayAuthorization,
  handleAddCyberSourceProfile,
  handleOrderPayment,
} from "../utils/helpers/helperPayment"
import { getParamsUrl } from "../redux/paramsUrl/paramsUrlSlice"

const usePayment = () => {
  const { retrievesAnOrder } = useAppSelector(getRetrievesAnOrderState)
  const { paramsUrl } = useAppSelector(getParamsUrl)

  const handlePayment = async (card: Card) => {
    const deviceSessionId = getDeviceSessionId()
    handleAddCyberSourceProfile(
      getOrganizationId(getEnvironmentType()),
      deviceSessionId,
    )
    const authorizationViancaPay = getAESEncrypt()
    const paymentTokens: PaymentTokens = {
      userAgent: window.navigator.userAgent,
      deviceSessionId,
      ipAddress: await getIpNavigation(),
      tokenPayU: await getTokenPayU(card, paramsUrl?.lang!),
      tokenAv: await getViancaPayAuthorization(authorizationViancaPay),
    }

    if (paymentTokens && retrievesAnOrder && paramsUrl && card) {
      const orderPayment: OrderPayment = {
        retrievesAnOrder: retrievesAnOrder,
        paymentTokens: paymentTokens,
        paramsUrl,
        card,
      }
      return handleOrderPayment(orderPayment)
    }
  }

  return {
    getTokenPayU,
    handlePayment,
  }
}

export default usePayment
