import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RetrievesAnOrder } from "../../models/retrievesAnOrder"
import { PnrRequest } from "../../models/general"
import { fetchRetrivesAnOrder } from "./retrievesAndOrderAPI"
import { RootState } from "../store"

export interface RetrievesAnOrderState {
  retrievesAnOrder?: RetrievesAnOrder | null
  Error?: Error
  status: "idle" | "loading" | "failed"
}

const initialState: RetrievesAnOrderState = {
  status: "idle",
}

export const setRetrievesAnOrderStateAsync = createAsyncThunk(
  "retrievesAnOrderState/fetchRetrievesAnOrder",
  async (pnr: PnrRequest) => {
    const response = await fetchRetrivesAnOrder(pnr)
    return response
  },
)

export const retrievesAnOrderSlice = createSlice({
  name: "retrievesAnOrderState",
  initialState,
  reducers: {
    setRetrievesAnOrderState: (
      state,
      action: PayloadAction<RetrievesAnOrder>,
    ) => {
      state.retrievesAnOrder = action.payload
    },
    setRetrievesAnOrderStateStatus: (
      state,
      action: PayloadAction<"idle" | "loading" | "failed">,
    ) => {
      state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setRetrievesAnOrderStateAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(setRetrievesAnOrderStateAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.retrievesAnOrder = action.payload
      })
      .addCase(setRetrievesAnOrderStateAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const getRetrievesAnOrderState = (state: RootState) =>
  state.retrievesAnOrderState

export const { setRetrievesAnOrderState, setRetrievesAnOrderStateStatus } =
  retrievesAnOrderSlice.actions
export default retrievesAnOrderSlice.reducer
