import { useTranslation } from "react-i18next"
import { TravelerTab } from "../../models/retrievesAnOrder"
const ParagraphCardTraveler = ({ traveler }: { traveler: TravelerTab }) => {
  const { t } = useTranslation()
  return (
    <>
      {!traveler.service?.hasAddLuggage && !traveler.service?.isPaid ? (
        <>
          <p className="hidden md:block font-avianca text-avianca-black-1 text-sm md:text-lg">
            {t("translation:Management.handLuggage")}
          </p>
        </>
      ) : (
        ""
      )}
      {traveler.service?.hasAddLuggage && !traveler.service?.isPaid ? (
        <>
          <p className="hidden md:block font-avianca text-avianca-black-1 text-sm md:text-lg">
            {t("translation:Management.AddedHandLuggage")}
          </p>
        </>
      ) : (
        ""
      )}
      {traveler.service?.isPaid ? (
        <>
          <p className="hidden md:block font-avianca text-avianca-black-1 text-sm md:text-lg">
            {t("translation:Management.handLuggageIncluded")}
          </p>
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default ParagraphCardTraveler
