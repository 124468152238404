
import takeoff from "../../../assets/icons/takeoff.svg"
import airplane from "../../../assets/icons/airplane.svg"
import ResumenCompraInfo from "./ResumenCompraInfo"

const ResumenCompraDsk = ({
  currency
}: {
  currency: string
}) => {
  return (
    <>
      <ResumenCompraInfo
        currency={currency}
      />
    </>
  )
}

export default ResumenCompraDsk
