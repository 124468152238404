import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import paramsUrlReducer from "./paramsUrl/paramsUrlSlice"
import retrievesAnOrderReducer from "./retrievesAnOrder/retrievesAndOrderSlice"
import catalogueTypeReducer from "./catalogueType/catalogueTypeSlice"

export const store = configureStore({
  reducer: {
    paramsUrl: paramsUrlReducer,
    retrievesAnOrderState: retrievesAnOrderReducer,
    catalogueTypeState: catalogueTypeReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
