import { FormEvent, useEffect, useState } from "react"
import usePayment from "../../../hooks/usePayment"
import visaIcon from "../../../assets/icons/visa.svg"
import americanIcon from "../../../assets/icons/icon-american-express.svg"
import dinersIcon from "../../../assets/icons/Diners.svg"
import masterCardIcon from "../../../assets/icons/MasterCard.svg"
import card_emptyIcon from "../../../assets/icons/card_empty.svg"
import valid from "../../../assets/icons/check.svg"
import https from "../../../assets/icons/https.svg"
import expand_less from "../../../assets/icons/expand_less.svg"
import { useTranslation } from "react-i18next"
import dataSelector from "./dataSelectors.json"
import { setRetrievesAnOrderStateStatus } from "../../../redux/retrievesAnOrder/retrievesAndOrderSlice"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { getParamsUrl } from "../../../redux/paramsUrl/paramsUrlSlice"
import { useNavigate } from "react-router"

let validationCard = card_emptyIcon
const initial = "border-b-2 border-[#969696] hover:border-[#007aa6]"
const error = "border-b-2 border-[#BF0006]"
const verified = "border-b-2 border-[#00A7A7]"
const validateNumber = /^[0-9]+$/
const validateNumberSecurity = /^[0-9]{1,2}[/][0-9]{1,2}$/
const validateText = /^[A-Z ]+$/i
const validateEmail =
  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

const PaymentMethods = () => {
  const { handlePayment } = usePayment()
  const { t } = useTranslation()
  const { paramsUrl } = useAppSelector(getParamsUrl)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [conditionsOfAccessConte, setConditionsOfAccessConte] = useState({
    value: false,
    bg: "border-gray-300",
    message: false,
    validacionBtn: false,
  })
  const [cardNumberConte, setCardNumberConte] = useState({
    value: "",
    bg: initial,
    message: false,
    length: false,
    validate: false,
  })
  const [expirationConte, setExpirationConte] = useState({
    value: "",
    bg: initial,
    message: false,
    length: false,
    validate: false,
  })
  const [expMonth, setExpMonth] = useState("")
  const [expYear, setExpYear] = useState("")
  const [nameCard, setNameCard] = useState("")
  const [longCard, setlongCard] = useState<Array<number>>([19])
  const [securityConte, setSecurityConte] = useState({
    value: "",
    bg: initial,
    message: false,
    length: false,
    validate: false,
  })
  const [installmentsConte, setInstallmentsConte] = useState({
    value: "",
    bg: initial,
    message: false,
  })
  const [firstNameConte, setFirstNameConte] = useState({
    value: "",
    bg: initial,
    message: false,
    length: false,
    validate: false,
  })
  const [surnameConte, setSurnameConte] = useState({
    value: "",
    bg: initial,
    message: false,
    length: false,
    validate: false,
  })
  const [documentTypeConte, setDocumentTypeConte] = useState({
    value: "",
    bg: initial,
    message: false,
  })
  const [idNumberConte, setIdNumberConte] = useState({
    value: "",
    bg: initial,
    message: false,
    length: false,
    validate: false,
  })
  const [emailConte, setEmailConte] = useState({
    value: "",
    bg: initial,
    message: false,
    length: false,
    validate: false,
  })
  const [countryCodeConte, setCountryCodeConte] = useState({
    value: "",
    bg: initial,
    message: false,
  })
  const [phoneConte, setPhoneConte] = useState({
    value: "",
    bg: initial,
    message: false,
    length: false,
    validate: false,
  })
  const [countryConte, setCountryConte] = useState({
    value: "",
    bg: initial,
    message: false,
  })
  const [cityConte, setCityConte] = useState({
    value: "",
    bg: initial,
    message: false,
    length: false,
    validate: false,
  })
  const [addressConte, setAddressConte] = useState({
    value: "",
    bg: initial,
    message: false,
    length: false,
    validate: false,
  })
  const [selectorsCountry, setDataSelectorsCountry] = useState([{}])
  const [selectorsTypeDoc, setDataSelectorsTypeDoc] = useState([{}])
  const [selectorsCountryCode, setDataSelectorsCountryCode] = useState([{}])

  useEffect(() => {
    setDataSelectorsCountry(dataSelector.data.countries.country)
    if (localStorage.getItem("i18nextLng") === "en") {
      setDataSelectorsTypeDoc(dataSelector.data.documentType.en)
    } else {
      setDataSelectorsTypeDoc(dataSelector.data.documentType.es)
    }
    setDataSelectorsCountryCode(
      dataSelector.data.countries.country.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }),
    )
  }, [localStorage.getItem("i18nextLng")])

  useEffect(() => {
    const validacionForm = { ...conditionsOfAccessConte }
    if (
      ![
        documentTypeConte.value,
        countryCodeConte.value,
        countryConte.value,
      ].includes("") &&
      conditionsOfAccessConte.value &&
      cardNumberConte.validate &&
      expirationConte.validate &&
      securityConte.validate &&
      firstNameConte.validate &&
      surnameConte.validate &&
      idNumberConte.validate &&
      emailConte.validate &&
      phoneConte.validate &&
      cityConte.validate &&
      addressConte.validate
    ) {
      validacionForm.validacionBtn = true
      setConditionsOfAccessConte(validacionForm)
      //iconSecure = iconSecureWithe
    } else {
      validacionForm.validacionBtn = false
      setConditionsOfAccessConte(validacionForm)
    }
  }, [
    cardNumberConte.validate,
    expirationConte.validate,
    securityConte.validate,
    installmentsConte.value,
    firstNameConte.validate,
    surnameConte.validate,
    documentTypeConte.value,
    idNumberConte.validate,
    emailConte.validate,
    countryCodeConte.value,
    phoneConte.validate,
    countryConte.value,
    cityConte.validate,
    addressConte.validate,
    conditionsOfAccessConte.value,
  ])

  const handleValidarTypeCard = (num: any) => {
    let numero = num.replace(/\D/g, "")
    let validate = false
    let numValidationCardOne = numero.slice(0, 1)
    let value = num
      .replace(/\s/g, "")
      .replace(/\D/g, "")
      .replace(/([0-9]{4})/g, "$1 ")
      .trim()
      .replaceAll(" ", "-")
    if (numValidationCardOne === "3") {
      let numValidationCardTwo = numero.slice(0, 2)
      if (numValidationCardTwo === "37" || numValidationCardTwo === "34") {
        validationCard = americanIcon
        setNameCard("AMEX")
        setlongCard([15])
      } else {
        validationCard = dinersIcon
        setNameCard("DINERS")
        setlongCard([16, 15, 14])
      }
    } else if (numValidationCardOne === "4") {
      validationCard = visaIcon
      setNameCard("VISA")
      setlongCard([16, 13])
    } else if (numValidationCardOne === "5") {
      validationCard = masterCardIcon
      setNameCard("MASTERCARD")
      setlongCard([16])
    } else {
      validationCard = card_emptyIcon
      setNameCard("")
      setlongCard([16])
    }
    if (longCard.includes(numero.length) && nameCard) {
      validate = true
    } else {
      validate = false
    }
    return {
      value,
      validate,
    }
  }

  const handleValidationDate = (data: any) => {
    let resp = false
    const date = new Date()
    let month = date.getMonth()
    let monthValidation = month + 1
    let year = date.getFullYear()
    let yearValidation = year.toString().substring(2, 4)
    let monthForm = data.substring(0, 2)
    let yearForm = data.substring(3, 5)
    if (monthForm <= 12) {
      if (yearValidation < yearForm) {
        resp = true
        setExpMonth(monthForm)
        setExpYear(yearForm)
      } else if (yearValidation === yearForm) {
        if (monthValidation <= monthForm) {
          resp = true
          setExpMonth(monthForm)
          setExpYear(yearForm)
        }
      }
    }
    return resp
  }

  const handleValidationInput = (input: any) => {
    const { name, value, checked } = input.target
    switch (name) {
      case "cardNumber":
        const newNumber = { ...cardNumberConte }
        newNumber.message = false
        if (value.replace(/\D/g, "").length <= longCard[0]) {
          let response = handleValidarTypeCard(value)
          newNumber.value = response.value
          newNumber.validate = response.validate
          if (response.validate) {
            newNumber.bg = verified
            newNumber.length = true
          } else {
            newNumber.bg = error
            newNumber.length = false
            newNumber.message = true
          }
        } else {
          if (!newNumber.length || !newNumber.validate) {
            newNumber.message = true
          }
        }
        setCardNumberConte(newNumber)
        break
      case "expiration":
        const newExpiration = { ...expirationConte }
        if (value) {
          newExpiration.message = false
          if (value.length === 5) {
            if (
              handleValidationDate(value) &&
              value.match(validateNumberSecurity)
            ) {
              newExpiration.bg = verified
              newExpiration.length = true
              newExpiration.validate = true
            } else {
              newExpiration.message = true
              newExpiration.bg = error
              newExpiration.length = false
              newExpiration.validate = false
              setExpMonth("")
              setExpYear("")
            }
          } else if (value.length < 5) {
            newExpiration.message = true
            newExpiration.bg = error
            newExpiration.length = false
            newExpiration.validate = false
          } else {
            newExpiration.message = true
            newExpiration.bg = error
            newExpiration.length = false
            newExpiration.validate = false
          }
          if (value.length <= 5) {
            newExpiration.value = value
              .replace(/\s/g, "")
              .replace(/\D/g, "")
              .replace(/([0-9]{2})/g, "$1 ")
              .trim()
              .replace(" ", "/")
          }
        } else {
          newExpiration.message = true
          newExpiration.bg = error
          newExpiration.value = value
          newExpiration.length = false
          newExpiration.validate = false
        }
        setExpirationConte(newExpiration)
        break
      case "security":
        const newSecurity = { ...securityConte }
        let lengthTypeCard = 3
        let typeCard = cardNumberConte.value.slice(0, 2)
        if (typeCard === "37" || typeCard === "34") {
          lengthTypeCard = 4
        }
        if (value) {
          newSecurity.message = false
          if (value.length >= lengthTypeCard) {
            newSecurity.bg = verified
            newSecurity.length = true
          } else {
            newSecurity.message = true
            newSecurity.bg = error
            newSecurity.length = false
            newSecurity.validate = false
          }
          if (value.length <= lengthTypeCard) {
            if (value.match(validateNumber)) {
              newSecurity.value = value
              value.length >= lengthTypeCard
                ? (newSecurity.validate = true)
                : (newSecurity.validate = false)
            }
          }
        } else {
          newSecurity.message = true
          newSecurity.bg = error
          newSecurity.value = value
          newSecurity.length = false
          newSecurity.validate = false
        }
        setSecurityConte(newSecurity)
        break
      case "firsName":
        const newFirstName = { ...firstNameConte }
        if (value) {
          newFirstName.message = false
          if (value.length >= 3) {
            newFirstName.bg = verified
            newFirstName.length = true
          } else {
            newFirstName.message = true
            newFirstName.bg = error
            newFirstName.length = false
            newFirstName.validate = false
          }
          if (value.length <= 15) {
            if (value.match(validateText)) {
              newFirstName.value = value
              value.length >= 3
                ? (newFirstName.validate = true)
                : (newFirstName.validate = false)
            }
          }
        } else {
          newFirstName.message = true
          newFirstName.bg = error
          newFirstName.value = value
          newFirstName.length = false
          newFirstName.validate = false
        }
        setFirstNameConte(newFirstName)
        break
      case "surname":
        const newSurname = { ...surnameConte }
        if (value) {
          newSurname.message = false
          if (value.length >= 3) {
            newSurname.bg = verified
            newSurname.length = true
          } else {
            newSurname.message = true
            newSurname.bg = error
            newSurname.length = false
            newSurname.validate = false
          }
          if (value.length <= 15) {
            if (value.match(validateText)) {
              newSurname.value = value
              value.length >= 3
                ? (newSurname.validate = true)
                : (newSurname.validate = false)
            }
          }
        } else {
          newSurname.message = true
          newSurname.bg = error
          newSurname.value = value
          newSurname.length = false
          newSurname.validate = false
        }
        setSurnameConte(newSurname)
        break
      case "documentTypeCD":
        const newDocu = { ...documentTypeConte }
        if (value) {
          newDocu.message = false
          newDocu.bg = verified
        } else {
          newDocu.message = true
          newDocu.bg = error
        }
        newDocu.value = value
        setDocumentTypeConte(newDocu)
        break
      case "idNumberCD":
        const newNumberID = { ...idNumberConte }
        if (value) {
          newNumberID.message = false
          if (value.length >= 7) {
            newNumberID.bg = verified
            newNumberID.length = true
          } else {
            newNumberID.message = true
            newNumberID.bg = error
            newNumberID.length = false
            newNumberID.validate = false
          }
          if (value.length <= 12) {
            if (value.match(validateNumber)) {
              newNumberID.value = value
              value.length >= 7
                ? (newNumberID.validate = true)
                : (newNumberID.validate = false)
            }
          }
        } else {
          newNumberID.message = true
          newNumberID.bg = error
          newNumberID.value = value
          newNumberID.length = false
          newNumberID.validate = false
        }
        setIdNumberConte(newNumberID)
        break
      case "email":
        const newEmail = { ...emailConte }
        if (value) {
          newEmail.message = true
          newEmail.bg = error
          newEmail.length = false
          newEmail.validate = true
          if (value.match(validateEmail)) {
            newEmail.message = false
            newEmail.bg = verified
            newEmail.length = true
            newEmail.validate = true
          }
          newEmail.value = value
        } else {
          newEmail.message = true
          newEmail.bg = error
          newEmail.value = value
          newEmail.validate = false
          newEmail.length = false
        }
        setEmailConte(newEmail)
        break
      case "countryCode":
        const newCountryCode = { ...countryCodeConte }
        if (value) {
          newCountryCode.message = false
          newCountryCode.bg = verified
        } else {
          newCountryCode.message = true
          newCountryCode.bg = error
        }
        newCountryCode.value = value
        setCountryCodeConte(newCountryCode)
        break
      case "phone":
        const newPhone = { ...phoneConte }
        if (value) {
          newPhone.message = false
          if (value.length >= 7) {
            newPhone.bg = verified
            newPhone.length = true
          } else {
            newPhone.message = true
            newPhone.bg = error
            newPhone.length = false
            newPhone.validate = false
          }
          if (value.length <= 12) {
            if (value.match(validateNumber)) {
              newPhone.value = value
              value.length >= 7
                ? (newPhone.validate = true)
                : (newPhone.validate = false)
            }
          }
        } else {
          newPhone.message = true
          newPhone.bg = error
          newPhone.value = value
          newPhone.length = false
          newPhone.validate = false
        }
        setPhoneConte(newPhone)
        break
      case "country":
        const newCountry = { ...countryConte }
        if (value) {
          newCountry.message = false
          newCountry.bg = verified
        } else {
          newCountry.message = true
          newCountry.bg = error
        }
        newCountry.value = value
        setCountryConte(newCountry)
        break
      case "city":
        const newCity = { ...cityConte }
        if (value) {
          newCity.message = false
          if (value.length >= 3) {
            newCity.bg = verified
            newCity.length = true
          } else {
            newCity.message = true
            newCity.bg = error
            newCity.length = false
            newCity.validate = false
          }
          if (value.length <= 15) {
            if (value.match(validateText)) {
              newCity.value = value
              value.length >= 3
                ? (newCity.validate = true)
                : (newCity.validate = false)
            }
          }
        } else {
          newCity.message = true
          newCity.bg = error
          newCity.value = value
          newCity.length = false
          newCity.validate = false
        }
        setCityConte(newCity)
        break
      case "address":
        const newAddress = { ...addressConte }
        if (value) {
          newAddress.message = false
          if (value.length >= 3) {
            newAddress.bg = verified
            newAddress.length = true
            newAddress.validate = true
          } else {
            newAddress.message = true
            newAddress.bg = error
            newAddress.length = false
            newAddress.validate = false
            newAddress.validate = false
          }
          if (value.length <= 30) {
            newAddress.value = value
            value.length >= 3
              ? (newAddress.validate = true)
              : (newAddress.validate = false)
          }
        } else {
          newAddress.message = true
          newAddress.bg = error
          newAddress.value = value
          newAddress.length = false
          newAddress.validate = false
        }
        setAddressConte(newAddress)
        break
      case "conditionsOfAccessCD":
        const newConditions = { ...conditionsOfAccessConte }
        if (checked) {
          newConditions.value = checked
          newConditions.message = false
        } else {
          newConditions.value = checked
          newConditions.message = true
          newConditions.bg = "border-red-400"
        }
        setConditionsOfAccessConte(newConditions)
        break
      default:
        break
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let btnSubmit = { ...conditionsOfAccessConte }
    btnSubmit.validacionBtn = false
    setConditionsOfAccessConte(btnSubmit)
    dispatch(setRetrievesAnOrderStateStatus("loading"))
    let countryCodeConteId: any = selectorsCountry.find((country: any) => {
      return country.id === countryCodeConte.value
    })
    let documentTypeConteCod: any = selectorsTypeDoc.find((type: any) => {
      return String(type.id) === documentTypeConte.value
    })

    try {
      const response = await handlePayment({
        cardNumber: cardNumberConte.value.replaceAll("-", ""),
        cardExpirationDate: "20" + expYear + "/" + expMonth,
        cardSecurityCode: securityConte.value,
        cardNumberOfinstallments: "1",
        cardFranchise: nameCard,
        firstName: firstNameConte.value.trim(),
        surname: surnameConte.value.trim(),
        documentTypeCod: documentTypeConte.value,
        documentType: documentTypeConteCod.cod,
        documentNumber: idNumberConte.value,
        email: emailConte.value,
        countryCode: countryCodeConteId.idc,
        phoneNumber: phoneConte.value,
        country: countryConte.value,
        city: cityConte.value.trim(),
        address: addressConte.value.trim(),
      })

      if (response != null && response !== undefined) {
        if (response.code === "SUCCESS") {
          return navigate(
            `/confirmation?recLoc=${paramsUrl?.recLoc}&lastName=${paramsUrl?.lastName}&lang=${paramsUrl?.lang}`,
            {
              state: {
                authorizationCode: response.authorizationCode,
                pnr: paramsUrl?.recLoc,
                status: response.code,
                statusResponse: response.state,
                nameCard: nameCard,
                cardNumber: cardNumberConte.value,
              },
            },
          )
        } else if (response.responseMessage === "REJECT") {
          return navigate(
            `/confirmation?recLoc=${paramsUrl?.recLoc}&lastName=${paramsUrl?.lastName}&lang=${paramsUrl?.lang}`,
            {
              state: {
                pnr: paramsUrl?.recLoc,
                status: response.responseMessage,
                cardNumber: cardNumberConte.value,
                nameCard: nameCard,
              },
            },
          )
        }
      } else {
        dispatch(setRetrievesAnOrderStateStatus("failed"))
        return navigate("/error", { state: { paramsUrl } })
      }
    } catch (error) {
      console.error(error)
    }
    btnSubmit.validacionBtn = true
  }

  return (
    <>
      <div className="md:flex justify-between text-avianca-black-1 font-normal font-avianca py-6 pb-4 md:py-6 px-[14px] md:px-8">
        <h2 className="text-lg md:text-xl md:leading-9">
          {t("translation:Payment.form.title")}
        </h2>
        <div className="flex text-right mt-0">
          <img src={visaIcon} alt="visaIcon" />
          <img src={masterCardIcon} alt="masterCardIcon" className="ml-2" />
          <img src={dinersIcon} alt="dinersIcon" className="ml-2" />
          <img src={americanIcon} alt="americanIcon" className="ml-2" />
        </div>
      </div>
      <div className="border border-neutral-200" />
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        className="px-[14px] md:px-8 mt-6   md:mt-8"
      >
        <h2 className="text-base md:text-xl text-avianca-black-1 font-avianca font-bold md:leading-8">
          {t("translation:Payment.form.subTitle1")}
        </h2>
        <div className="font-avianca relative z-0 w-full group h-[80px] md:h-[88px] mt-2 md:mt-4 pt-4">
          <div className={`flex items-center ${cardNumberConte.bg}`}>
            <img src={validationCard} className="flex-shrink-0" alt="check" />
            <input
              type="text"
              name="cardNumber"
              id="cardNumber"
              className={`block pt-[4px] pb-[8px] md:pt-[12px] md:pb-[7px] px-2 w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer 
                ${cardNumberConte.length ? "cardNumberInput" : ""}`}
              placeholder=" "
              value={cardNumberConte.value}
              onChange={handleValidationInput}
            />
            {cardNumberConte.length ? (
              <img src={valid} alt="" className="flex-shrink-0" />
            ) : (
              ""
            )}
            <label
              htmlFor="cardNumber"
              className="cardNumber text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-7 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6"
            >
              {t("translation:Payment.form.cardNumber")}
            </label>
          </div>
          {cardNumberConte.message ? (
            <p className="text-avianca-red-2 text-xs font-normal mt-1">
              {t("translation:Payment.form.errorCardNumber")}
            </p>
          ) : (
            <p className="text-avianca-gray-4 text-xs font-normal mt-1">
              {t("translation:Payment.form.helpCardNumber")}
            </p>
          )}
        </div>
        <div className="flex h-[80px] md:h-[88px] mt-4 pt-4">
          <div className="w-1/2 mr-4 font-avianca relative z-0 group">
            <div className={`flex items-center ${expirationConte.bg}`}>
              <input
                type="text"
                name="expiration"
                id="expiration"
                className="block pt-[4px] pb-[8px] md:pt-[12px] md:pb-[7px] px-0 w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer"
                placeholder=" "
                value={expirationConte.value}
                onChange={handleValidationInput}
              />
              {expirationConte.length ? (
                <img src={valid} alt="" className="flex-shrink-0" />
              ) : (
                ""
              )}
              <label
                htmlFor="expiration"
                className="text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6"
              >
                {t("translation:Payment.form.expiration")}
              </label>
            </div>
            {expirationConte.message ? (
              <p className="text-avianca-red-2 text-xs font-normal mt-1">
                {t("translation:Payment.form.errorExpiration")}
              </p>
            ) : (
              <p className="text-avianca-gray-4 text-xs font-normal mt-1">
                {t("translation:Payment.form.helpExpiration")}
              </p>
            )}
          </div>
          <div className="w-1/2 ml-4 font-avianca relative z-0 group">
            <div className={`flex items-center ${securityConte.bg}`}>
              <input
                type="text"
                name="security"
                id="security"
                className="block pt-[4px] pb-[8px] md:pt-[12px] md:pb-[7px] px-0 w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer"
                placeholder=" "
                value={securityConte.value}
                onChange={handleValidationInput}
              />
              {securityConte.length ? (
                <img src={valid} alt="" className="flex-shrink-0" />
              ) : (
                ""
              )}
              <label
                htmlFor="security"
                className="text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6"
              >
                {t("translation:Payment.form.cvv")}
              </label>
            </div>
            {securityConte.message ? (
              <p className="text-avianca-red-2 text-xs font-normal mt-1">
                {t("translation:Payment.form.errorCvv")}
              </p>
            ) : (
              <p className="text-avianca-gray-4 text-xs font-normal mt-1">
                {t("translation:Payment.form.helpCvv")}
              </p>
            )}
          </div>
        </div>
        <h2 className="text-base md:text-xl text-avianca-black-1 font-avianca font-bold mt-6 md:mt-8">
          {t("translation:Payment.form.subTitle2")}
        </h2>
        <div className="md:flex md:h-[88px] mt-2 md:mt-4 md:pt-4">
          <div className="h-[80px] md:h-[88px] md:w-1/2 md:mr-4 font-avianca relative z-0 w-full group">
            <div
              className={`flex items-end md:items-center h-[60px] md:h-auto ${firstNameConte.bg}`}
            >
              <input
                type="text"
                name="firsName"
                id="firsName"
                className="block pt-[4px] pb-[8px] md:pt-[12px] md:pb-[7px] px-0 w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer"
                placeholder=" "
                value={firstNameConte.value}
                onChange={handleValidationInput}
              />
              {firstNameConte.length ? (
                <img src={valid} alt="" className="flex-shrink-0" />
              ) : (
                ""
              )}
              <label
                htmlFor="firsName"
                className="text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-7 md:top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6"
              >
                {t("translation:Payment.form.name")}
              </label>
            </div>
            {firstNameConte.message ? (
              <p className="text-avianca-red-2 text-xs font-normal mt-1">
                {t("translation:Payment.form.errorName")}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="h-[80px] md:h-[88px] md:w-1/2 md:ml-4 font-avianca relative z-0 w-full group mt-4 md:mt-0">
            <div
              className={`flex items-end md:items-center h-[60px] md:h-auto ${surnameConte.bg}`}
            >
              <input
                type="text"
                name="surname"
                id="surname"
                className="block pt-[4px] pb-[8px] md:pt-[12px] md:pb-[7px] px-0 w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer"
                placeholder=" "
                value={surnameConte.value}
                onChange={handleValidationInput}
              />
              {surnameConte.length ? (
                <img src={valid} alt="" className="flex-shrink-0" />
              ) : (
                ""
              )}
              <label
                htmlFor="surname"
                className="text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-7 md:top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6"
              >
                {t("translation:Payment.form.surname")}
              </label>
            </div>
            {surnameConte.message ? (
              <p className="text-avianca-red-2 text-xs font-normal mt-1">
                {t("translation:Payment.form.errorSurname")}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="md:flex md:h-[88px] mt-4 md:pt-4">
          <div className="h-[80px] md:h-[88px] md:w-1/2 md:mr-3 font-avianca relative z-0 w-full group">
            <label
              htmlFor="documentTypeCD"
              className="text-lg md:text-xl font-bold absolute text-avianca-gray-5 duration-300 transform -translate-y-7 scale-[0.65] top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6 pt-4 md:pt-0"
            >
              {t("translation:Payment.form.typeDocument")}
            </label>
            <div
              className={`flex items-center md:mt-[7px] ${documentTypeConte.bg}`}
            >
              <select
                id="documentTypeCD"
                name="documentTypeCD"
                className={`pt-[28px] md:py-[7px] px-0 w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer`}
                onChange={handleValidationInput}
              >
                <option value="">
                  {t("translation:Payment.form.typeDocument")}
                </option>
                {selectorsTypeDoc.map((item: any, key: number) => {
                  return (
                    <option value={item.id} key={key}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
              <img src={expand_less} alt="arrow bottom" />
            </div>
            {documentTypeConte.message ? (
              <p className="text-avianca-red-2 text-xs font-normal mt-1">
                {t("translation:Payment.form.errorTypeDocument")}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="h-[80px] md:h-[88px] md:w-1/2 md:ml-3 font-avianca relative z-0 w-full group mt-4 md:mt-0">
            <div className={`flex items-center ${idNumberConte.bg}`}>
              <input
                type="text"
                name="idNumberCD"
                id="idNumberCD"
                className="block pt-5 pb-2 md:py-[7px] px-0 w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer"
                placeholder=" "
                onChange={handleValidationInput}
              />
              {idNumberConte.length ? (
                <img src={valid} alt="" className="flex-shrink-0" />
              ) : (
                ""
              )}
              <label
                htmlFor="idNumberCD"
                className="text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6 pt-4 md:pt-0"
              >
                {t("translation:Payment.form.identifyNumber")}
              </label>
            </div>
            {idNumberConte.message ? (
              <p className="text-avianca-red-2 text-xs font-normal mt-1">
                {t("translation:Payment.form.errorIdentifyNumber")}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="font-avianca relative z-0 w-full group h-[80px] md:h-[88px] mt-4 pt-4">
          <div className={`flex items-center ${emailConte.bg}`}>
            <input
              type="email"
              name="email"
              id="email"
              className="block pt-[4px] pb-[8px] md:pt-[12px] md:pb-[7px] w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer"
              placeholder=" "
              value={emailConte.value}
              onChange={handleValidationInput}
            />
            {emailConte.length ? (
              <img src={valid} alt="" className="flex-shrink-0" />
            ) : (
              ""
            )}
            <label
              htmlFor="email"
              className="text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-7 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6"
            >
              {t("translation:Payment.form.email")}
            </label>
          </div>
          {emailConte.message ? (
            <p className="text-avianca-red-2 text-xs font-normal mt-1">
              {t("translation:Payment.form.errorEmail")}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="flex h-[80px] md:h-[88px] mt-4 pt-4">
          <div className="w-1/2 mr-4 font-avianca relative z-0 group">
            <label
              htmlFor="countryCode"
              className="text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6"
            >
              {t("translation:Payment.form.codeCountry")}
            </label>
            <div
              className={`flex items-center mt-[7px] ${countryCodeConte.bg}`}
            >
              <select
                id="countryCode"
                name="countryCode"
                className={`py-[3.5px] md:py-[7px] px-0 w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer`}
                onChange={handleValidationInput}
              >
                <option value="">
                  {t("translation:Payment.form.codeCountry")}
                </option>
                {selectorsCountryCode.map((item: any, key: number) => {
                  if (countryCodeConte.value === item.id) {
                    return (
                      <option value={item.id} key={key}>
                        {`+(${item.idc})`}
                      </option>
                    )
                  } else {
                    return (
                      <option value={item.id} key={key}>
                        {`${item.name} +(${item.idc})`}
                      </option>
                    )
                  }
                })}
              </select>
              <img src={expand_less} alt="arrow bottom" />
            </div>
            {countryCodeConte.message ? (
              <p className="text-avianca-red-2 text-xs font-normal mt-1">
                {t("translation:Payment.form.errorCodeCountry")}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="w-1/2 ml-4 font-avianca relative z-0 group">
            <div className={`flex items-center ${phoneConte.bg}`}>
              <input
                type="text"
                name="phone"
                id="phone"
                className="block py-[7px] px-0 w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer"
                placeholder=" "
                value={phoneConte.value}
                onChange={handleValidationInput}
              />
              {phoneConte.length ? (
                <img src={valid} alt="" className="flex-shrink-0" />
              ) : (
                ""
              )}
              <label
                htmlFor="phone"
                className="text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6"
              >
                {t("translation:Payment.form.phone")}
              </label>
            </div>
            {phoneConte.message ? (
              <p className="text-avianca-red-2 text-xs font-normal mt-1">
                {t("translation:Payment.form.errorPhone")}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <h2 className="text-base md:text-xl text-avianca-black-1 font-avianca font-bold mt-6 md:mt-8">
          {t("translation:Payment.form.subTitle3")}
        </h2>
        <div className="md:flex md:h-[88px] mt-2 md:mt-4 md:pt-4">
          <div className="h-[80px] md:h-[88px] md:w-1/2 md:mr-4 font-avianca relative z-0 w-full group ">
            <label
              htmlFor="country"
              className="text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-0 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6 pt-4 md:mt-0"
            >
              {t("translation:Payment.form.country")}
            </label>
            <div className={`flex items-center mt-[7px] ${countryConte.bg}`}>
              <select
                id="country"
                name="country"
                className={`appearance-none pt-4 pb-1 md:py-[7px] px-0 w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0  focus:outline-none focus:ring-0 peer`}
                onChange={handleValidationInput}
              >
                <option className="appearance-none" value="">
                  {t("translation:Payment.form.country")}
                </option>
                {selectorsCountry.map((item: any, key: number) => {
                  return (
                    <option
                      className="appearance-none"
                      value={item.alpha2_code}
                      key={key}
                    >
                      {`${item.name}`}
                    </option>
                  )
                })}
              </select>
              <img src={expand_less} alt="arrow bottom" />
            </div>
            {countryConte.message ? (
              <p className="text-avianca-red-2 text-xs font-normal mt-1">
                {t("translation:Payment.form.errorCountry")}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="h-[80px] md:h-[88px] md:w-1/2 md:ml-4 font-avianca relative z-0 w-full group mt-4 md:mt-0">
            <div className={`flex items-center ${cityConte.bg}`}>
              <input
                type="text"
                name="city"
                id="city"
                className="block pt-5 pb-1 md:py-[7px] px-0 w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer"
                placeholder=" "
                value={cityConte.value}
                onChange={handleValidationInput}
              />
              {cityConte.length ? (
                <img src={valid} alt="" className="flex-shrink-0" />
              ) : (
                ""
              )}
              <label
                htmlFor="city"
                className="text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6 pt-4 md:pt-0"
              >
                {t("translation:Payment.form.city")}
              </label>
            </div>
            {cityConte.message ? (
              <p className="text-avianca-red-2 text-xs font-normal mt-1">
                {t("translation:Payment.form.errorCity")}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="font-avianca relative z-0 w-full group h-[80px] md:h-[88px] mt-2 md:mt-4 pt-4">
          <div className={`flex items-center ${addressConte.bg}`}>
            <input
              type="text"
              name="address"
              id="address"
              className="block pt-[4px] pb-[8px] md:pt-[12px] md:pb-[7px] w-full text-lg md:text-2xl md:leading-6 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer"
              placeholder=" "
              value={addressConte.value}
              onChange={handleValidationInput}
            />
            {addressConte.length ? (
              <img src={valid} alt="" className="flex-shrink-0" />
            ) : (
              ""
            )}
            <label
              htmlFor="address"
              className="text-lg md:text-xl font-bold absolute text-avianca-gray-4 duration-300 transform -translate-y-7 scale-[0.65] top-7 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-avianca-gray-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[0.65] peer-focus:-translate-y-7 md:leading-6"
            >
              {t("translation:Payment.form.address")}
            </label>
          </div>
          {addressConte.message ? (
            <p className="text-avianca-red-2 text-xs font-normal mt-1">
              {t("translation:Payment.form.errorAddress")}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="mt-8 flex">
          <input
            id="conditionsOfAccessCD"
            name="conditionsOfAccessCD"
            type="checkbox"
            value=""
            className="w-[20px] h-[20px] md:w-[24px] md:h-[24px] flex-shrink-0 appearance-none border border-black rounded-sm checked:bg-icon-active checked:bg-cover checked:border-none"
            checked={conditionsOfAccessConte.value}
            onChange={handleValidationInput}
          />
          <label
            className="ml-2 text-avianca-black-1 font-avianca text-[12px] md:text-base font-normal text-justify"
            htmlFor="conditionsOfAccessCD"
          >
            {`${t("translation:Payment.termsAndConditions.iHaveRead")} `}
            <a
              href={t("translation:Payment.links.transportContract")}
              className="font-semibold underline underline-offset-1"
              target="_blank"
            >
              {`${t(
                "translation:Payment.termsAndConditions.transportContract",
              )}`}
            </a>
            {`, ${t("translation:Payment.termsAndConditions.the")} `}
            <a
              href={t("translation:Payment.links.generalPrivacyPolicy")}
              className="font-semibold underline underline-offset-1"
              target="_blank"
            >
              {`${t(
                "translation:Payment.termsAndConditions.generalPrivacyPolicy",
              )}`}
            </a>
            {`, ${t(
              "translation:Payment.termsAndConditions.theConditionsOf",
            )} `}
            <a
              href={t("translation:Payment.links.restrictedArticles")}
              className="font-semibold underline underline-offset-1"
              target="_blank"
            >
              {t("translation:Payment.termsAndConditions.restrictedArticles")}
            </a>
            {`, ${t("translation:Payment.termsAndConditions.the")} `}
            <a
              href={t("translation:Payment.links.cancellationPolicy")}
              className="font-semibold underline underline-offset-1"
              target="_blank"
            >
              {t("translation:Payment.termsAndConditions.cancellationPolicy")}
            </a>
            {` ${t("translation:Payment.termsAndConditions.andThe")} `}
            <a
              href={t("translation:Payment.links.termsAndConditions")}
              className="font-semibold underline underline-offset-1"
              target="_blank"
            >
              {t("translation:Payment.termsAndConditions.termsAndConditions")}
            </a>
          </label>
        </div>
        <div className="flex justify-end mt-8 mb-10">
          <button
            className={`h-12 w-full md:w-56 p-6 text-center inline-flex items-center justify-center rounded-md 
            ${
              conditionsOfAccessConte.validacionBtn
                ? "text-white bg-avianca-red-1 hover:bg-avianca-red-2"
                : "text-white bg-avianca-gray-4"
            }`}
            disabled={!conditionsOfAccessConte.validacionBtn}
          >
            <img src={https} alt="" className="mr-2" />
            <p className="text-base font-avianca font-bold">
              {t("translation:Payment.form.button")}
            </p>
          </button>
        </div>
      </form>
    </>
  )
}

export default PaymentMethods
