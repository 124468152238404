import { useState } from "react"
import { BoundTab } from "../../models/retrievesAnOrder"
import TabsBodyFlights from "./TabsBodyFlights"
import TabsHeaderFlights from "./TabsHeaderFlights"
import { HandleTabsBounds } from "../../models/tabsBounds"

const TabsFlights = ({
  bounds,
  handleToggleCABG,
  isHasRequestDeleteActive,
}: {
  bounds: BoundTab[]
  handleToggleCABG: Function
  isHasRequestDeleteActive: boolean
}) => {
  const [tabSelected, setTabSelected] = useState(bounds[0].airBoundId)

  const handleTabsSelected = (tab: string) => {
    setTabSelected(tab)
  }

  const handleTabsBounds: HandleTabsBounds = {
    tabSelected,
    handleTabsSelected,
    handleToggleCABG,
  }

  return (
    <>
      <div className="md:mt-7 border-b border-avianca-gray-2 md:mr-4">
        <ul className="flex flex-wrap justify-between md:justify-normal -mb-px text-sm font-medium text-center">
          <TabsHeaderFlights
            bounds={bounds}
            handleTabsBounds={handleTabsBounds}
          />
        </ul>
      </div>
      <>
        <TabsBodyFlights
          bounds={bounds}
          handleTabsBounds={handleTabsBounds}
          isHasRequestDeleteActive={isHasRequestDeleteActive}
        />
      </>
    </>
  )
}

export default TabsFlights
