import {
  CatalogueType,
  Service as ServiceCatalogue,
} from "../../models/catalogueType"
import {
  BoundTab,
  RetrievesAnOrder,
  TravelerTab,
  DictionariesLocation,
  Service,
  Traveler,
  PaymentRecord,
  ServiceStateTab,
  Dictionaries,
} from "../../models/retrievesAnOrder"

const handleLocationName = (dictionaries: Dictionaries, code: string) => {
  let locationName: string | undefined = ""
  const location = dictionaries?.location[code as keyof DictionariesLocation]
  if (location?.cityName) {
    locationName = location.cityName
  }
  if (!location?.cityName) {
    locationName =
      dictionaries?.location[location?.cityCode as keyof DictionariesLocation]
        .cityName
  }
  return locationName
}

const handleBounds = (
  retrievesAnOrder: RetrievesAnOrder,
): BoundTab[] | undefined => {
  const boundsWhitDictionaries = retrievesAnOrder.data?.air.bounds.map(
    (bound) => {
      let fareFamilyCode = ""
      let stDeparture = ""
      let originLocationName: string | undefined = ""
      let destinationLocationName: string | undefined = ""
      const flights = bound.flights.map((flight, index) => {
        const dictionary = retrievesAnOrder?.dictionaries?.flight[flight.id]
        if (index === 0 && flight.fareFamilyCode !== undefined) {
          fareFamilyCode = flight.fareFamilyCode
          stDeparture = flight.id
        }
        return { ...flight, dictionary }
      })

      if (retrievesAnOrder.dictionaries) {
        destinationLocationName = handleLocationName(
          retrievesAnOrder.dictionaries,
          bound.destinationLocationCode,
        )
        originLocationName = handleLocationName(
          retrievesAnOrder.dictionaries,
          bound.originLocationCode,
        )
      }

      const travelers = handleTravelers(retrievesAnOrder, stDeparture)
      return {
        ...bound,
        flights,
        fareFamilyCode,
        stDeparture,
        numberFlightsForBound: flights.length,
        travelers,
        originLocationName,
        destinationLocationName,
        isActive: true,
      }
    },
  )
  return boundsWhitDictionaries
}

const searchServicesByTraveler = (
  services: Service[],
  traveler: Traveler,
  stDeparture: string,
) => {
  return services.find((service) => {
    return (
      service.travelerId === traveler.id &&
      service.descriptions[0].content === "CABG" &&
      service.flightIds.includes(stDeparture)
    )
  })
}

const searchForPaidServicesPerTraveler = (
  paymentRecords: PaymentRecord[],
  serviceId: string,
) => {
  return paymentRecords.find((paymentService) => {
    return paymentService.serviceItemIds?.find((serviceID) => {
      return serviceID === serviceId
    })
  })
}

const handleTravelers = (
  retrievesAnOrder: RetrievesAnOrder,
  stDeparture: string,
) => {
  const travelers: TravelerTab[] | undefined =
    retrievesAnOrder.data?.travelers.map((traveler) => {
      const newService: ServiceStateTab = {
        isPaid: false,
        hasAddLuggage: false,
      }
      const newTraveler: TravelerTab = {
        ...traveler,
        service: newService,
        isLoader: false,
      }
      if (retrievesAnOrder.data?.services) {
        const service = searchServicesByTraveler(
          retrievesAnOrder.data?.services,
          traveler,
          stDeparture,
        )
        if (service !== undefined) {
          const payService = searchForPaidServicesPerTraveler(
            retrievesAnOrder.data.paymentRecords,
            service.id,
          )
          if (newTraveler.service) {
            newTraveler.service.service = service
            newTraveler.service.hasAddLuggage = true
            if (payService !== undefined) {
              newTraveler.service.isPaid = true
            }
          }
          return { ...newTraveler }
        }
      }
      return { ...newTraveler }
    })
  return travelers
}

const searchServiceInCatalogue = (
  services: ServiceCatalogue[],
  type: string,
) => {
  return services.filter((service) => {
    return service.descriptions.find((description) => {
      return description.content === type
    })
  })
}
const handleServicesCode = (services: ServiceCatalogue) => {
  return {
    STS: services.id.split("_").filter((item) => item.includes("ST")),
    code: services,
  }
}

const handleFareFamilyCode = ({
  newBounds,
  catalogueType,
}: {
  newBounds: Array<BoundTab> | undefined
  catalogueType: CatalogueType
}) => {
  if (catalogueType?.data && newBounds !== undefined) {
    const servicesCabg = searchServiceInCatalogue(
      catalogueType?.data[0].services,
      "CABG",
    )
    if (servicesCabg !== undefined) {
      const servicesCode = servicesCabg.map(handleServicesCode)
      const bounds = newBounds.map((bound) => {
        const code = servicesCode.find((serviceCode) => {
          return bound.numberFlightsForBound === serviceCode.STS.length
            ? serviceCode.STS.find((st) => st === bound.stDeparture)
            : undefined
        })
        return {
          ...bound,
          serviceCABG: code?.code,
        }
      })
      return bounds
    }
    return newBounds
  }
}

const handleValidateBound = (bound: BoundTab) => {
  if (!bound.serviceCABG) {
    return { ...bound, isActive: false }
  }

  const validateFlightStatus = !bound.flights.every((flight) => {
    return flight.dictionary?.flightStatus === "scheduled"
  })
  if (validateFlightStatus) return { ...bound, isActive: false }

  const newTravelers = bound.travelers?.filter(
    (traveler) => traveler.passengerTypeCode !== "INF",
  )
  if (
    newTravelers?.find((traveler) => !traveler.service?.isPaid) === undefined
  ) {
    return { ...bound, isActive: false }
  }

  return bound
}

export const handleNewBounds = ({
  retrievesAnOrder,
  catalogueType,
}: {
  retrievesAnOrder: RetrievesAnOrder
  catalogueType: CatalogueType
}) => {
  const newBounds = handleBounds(retrievesAnOrder)
  const boundsWithFareFamilyCode = handleFareFamilyCode({
    newBounds,
    catalogueType,
  })
  return boundsWithFareFamilyCode
    ? boundsWithFareFamilyCode.map(handleValidateBound)
    : boundsWithFareFamilyCode
}

export const helperCapitalizeString = (text: string = ""): string => {
  const textLower = text.toLowerCase().split("")
  if (textLower.length > 0) {
    textLower[0] = textLower[0].toUpperCase()
    const textCapitalize = textLower.toString()
    return textCapitalize.replace(/,/g, "")
  } else {
    return text
  }
}

export const handleCurrencyToServices = (
  retrievesAnOrder: RetrievesAnOrder,
) => {
  const services = getServices(retrievesAnOrder)
  const paymentRecords = getPaymentRecords(retrievesAnOrder)
  const unpaidServices = getUnpaidServices(services, paymentRecords)
  return unpaidServices?.filter((unpaidServices) => {
    return unpaidServices.prices.totalPrices[0].total.currencyCode !== "COP"
  })
}

const getServices = (retrievesAnOrder: RetrievesAnOrder) => {
  return retrievesAnOrder.data?.services
}

const getPaymentRecords = (retrievesAnOrder: RetrievesAnOrder) => {
  return retrievesAnOrder.data?.paymentRecords
}

const getUnpaidServices = (
  services: Service[] | undefined,
  paymentRecords: PaymentRecord[] | undefined,
) => {
  return services?.filter((service) => {
    const paymentRecordss = paymentRecords?.find((paymentRecord) => {
      return paymentRecord.serviceItemIds?.find((serviceID) => {
        return serviceID === service.id
      })
    })
    return paymentRecordss === undefined
  })
}
