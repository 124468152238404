const headerBase = {
  headers: {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": import.meta.env
      .VITE_REACT_APP_APIDXAPI_HEADER_SUBSCRIPTION_KEY,
  },
}

export const structureRequests = {
  baseURLDapi: `${import.meta.env.VITE_REACT_APP_APIDXAPI_BASE_URL}/`,
  baseURLViancaPay: `${import.meta.env.VITE_REACT_APP_VIANCAPAY_BASE_URL}/`,

  headersWithOid: {
    headers: {
      ...headerBase.headers,
      OID: import.meta.env.VITE_REACT_APP_APIDXAPI_HEADER_OID,
      Authorization: "",
      Canal: "",
      Accept: "application/json",
    },
  },

  headersViancaPay: {
    headers: {
      ...headerBase.headers,
      "Ocp-Apim-Subscription-Key": import.meta.env
        .VITE_REACT_APP_VIANCAPAY_HEADER_SUBSCRIPTION_KEY,
      Accept: "*/*",
    },
  },

  headersViancaPayWithToken: {
    headers: {
      "Ocp-Apim-Subscription-Key": import.meta.env
        .VITE_REACT_APP_VIANCAPAY_HEADER_SUBSCRIPTION_KEY,
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: "",
    },
  },
}
