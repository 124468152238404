import { HandleTabsBounds } from "../../models/tabsBounds"
import { BoundTab } from "../../models/retrievesAnOrder"
import { useEffect, useState } from "react"
import { helperCapitalizeString } from "../../utils/helpers/helperBounds"

const TabHeaderFlight = ({
  bound,
  keyBound,
  handleTabsBounds,
}: {
  bound: BoundTab
  keyBound: string
  handleTabsBounds: HandleTabsBounds
}) => {
  const [activeTab, setActiveTab] = useState(false)

  useEffect(() => {
    if (keyBound === handleTabsBounds.tabSelected) {
      setActiveTab(true)
    } else {
      setActiveTab(false)
    }
  }, [keyBound, handleTabsBounds.tabSelected])
  return (
    <>
      <li
        className="flex min-w-[127px] md:mr-1 w-[49%] h-[41px] md:w-auto"
        role="presentation"
      >
        <button
          className={`grow flex justify-center items-center w-full md:hidden font-avianca-bold border-avianca-gray-2 text-sm md:text-base leading-4 ${
            activeTab
              ? " bg-white border-t border-x rounded-t-sm active:border-avianca-gray-2 pt-[13px] pb-[12px]"
              : "bg-avianca-gray-1 border-b active:border-avianca-gray-2 mt-[3px] pt-[11px] pb-[11px]"
          } `}
          type="button"
          role="tab"
          aria-selected={activeTab}
          onClick={() => handleTabsBounds.handleTabsSelected(keyBound)}
        >
          {bound.originLocationCode} - {bound.destinationLocationCode}
        </button>
        <button
          className={`hidden md:grow md:flex justify-center items-center px-5 font-avianca-bold font-bold border-avianca-gray-2 text-base leading-4 ${
            activeTab
              ? "bg-white border-t border-x rounded-t-sm active:border-avianca-gray-2 pt-[13px] pb-[12px]"
              : "bg-avianca-gray-1 border active:border-avianca-gray-2 mt-[3px] pt-[11px] pb-[11px]"
          }`}
          type="button"
          role="tab"
          aria-selected={activeTab}
          onClick={() => handleTabsBounds.handleTabsSelected(keyBound)}
        >
          {`
            ${helperCapitalizeString(
              bound.originLocationName,
            )} - ${helperCapitalizeString(bound.destinationLocationName)}
          `}
        </button>
      </li>
    </>
  )
}

export default TabHeaderFlight
