import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import translationES from "./assets/translations/es/translation.json"
import translationEN from "./assets/translations/en/translation.json"

const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "es",
    resources,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
