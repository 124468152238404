import { Outlet, useLocation } from "react-router"
import Header from "../components/headers/Header"
import Footer from "../components/footers/Footer"
import Loader from "../components/Loaders/Loader"
import { useAppSelector } from "../redux/hooks"
import { useEffect, useState } from "react"

const Layaout = () => {
  const [isLoading, setIsLoading] = useState<boolean>()
  const location = useLocation()
  const [isPageStatic, setIsPageStatic] = useState<boolean>(false)
  const statusRequestRetrieves = useAppSelector(
    (state) => state.retrievesAnOrderState.status,
  )
  const statusRequestCatalogue = useAppSelector(
    (state) => state.catalogueTypeState.status,
  )

  useEffect(() => {
    if (["/information", "/error"].includes(location.pathname)) {
      setIsPageStatic(true)
    }
  }, [location])

  useEffect(() => {
    if (
      statusRequestRetrieves === "loading" ||
      statusRequestCatalogue === "loading"
    ) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [statusRequestRetrieves, statusRequestCatalogue])

  return (
    <>
      <div
        className={`
          ${
            isPageStatic
              ? "tallMedia:h-[calc(100vh-50px)] h-screen md:min-h-screen"
              : "min-h-screen"
          }
          w-full flex flex-col box-border`}
      >
        <Header isPageStatic={isPageStatic} />
        <main
          className={`
          ${isLoading ? "hidden" : "grow shrink-0 flex justify-center"}
          ${isPageStatic ? "my-auto" : ""}`}
        >
          <Outlet />
        </main>
        <Footer isPageStatic={isPageStatic} />
        {isLoading ? <Loader /> : ""}
      </div>
    </>
  )
}

export default Layaout
