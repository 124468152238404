import { Routes, Route, Navigate } from "react-router-dom"
import Layaout from "../pages/Layout"
import Management from "../pages/Management"
import Payment from "../pages/Payment"
import Confirmation from "../pages/Confirmation"
import Information from "../pages/Information"
import PageError from "../pages/PageError"

export const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route path="management" element={<Layaout />}>
          <Route path="" element={<Management />}></Route>
        </Route>
        <Route path="payment" element={<Layaout />}>
          <Route path="" element={<Payment />}></Route>
        </Route>
        <Route path="confirmation" element={<Layaout />}>
          <Route path="" element={<Confirmation />}></Route>
        </Route>
        <Route path="information" element={<Layaout />}>
          <Route path="" element={<Information />}></Route>
        </Route>
        <Route path="error" element={<Layaout />}>
          <Route path="" element={<PageError />}></Route>
        </Route>
        <Route path="*" element={<Navigate to="management" />}></Route>
      </Routes>
    </>
  )
}

export default AppRouter
