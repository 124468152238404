import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  getParamsUrl,
  setParamsUrl,
} from "../../redux/paramsUrl/paramsUrlSlice"
import i18n from "../../i18n"
import { useTranslation } from "react-i18next"
import iconExpandMore from "../../assets/icons/icon-expand-more.png"
import iconlanguage from "../../assets/icons/icon-language.svg"
import { handleLang } from "../../utils/helpers/helperLang"

const LangSelect = () => {
  const { paramsUrl } = useAppSelector(getParamsUrl)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [lang, setLang] = useState<string>()
  const [isOpenList, setIsOpenList] = useState<boolean>(false)

  useEffect(() => {
    if (paramsUrl?.lang) {
      setLang(paramsUrl.lang)
    }
  }, [paramsUrl])

  const handleOpenList = (event: React.FormEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    setIsOpenList(!isOpenList)
  }

  const handleLangSelect = (event: React.FormEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    handleLang(event.currentTarget.id)
    dispatch(setParamsUrl({ lang: event.currentTarget.id }))
    i18n.changeLanguage(event.currentTarget.id?.toLocaleLowerCase())
    setIsOpenList(!isOpenList)
  }

  return (
    <>
      <div className="flex relative">
        <div className="flex">
          <a
            href="/#"
            className="text-white text-base flex items-center"
            onClick={handleOpenList}
          >
            <img
              className="mx-2 w-[24px]"
              src={iconlanguage}
              alt="Icon laguage"
            />
            <p className="hidden md:block leading-4">
              {t(`translation:langSelect.${lang}`)}
            </p>
            <img
              className="ml-2 hidden md:block"
              src={iconExpandMore}
              alt="Icon expand more"
            />
          </a>
        </div>
        <ul
          className={`${isOpenList ? "" : "hidden"}
          flex flex-col bg-white border-0 rounded absolute bottom-[-85px] right-0 shadow-lg z-10`}
        >
          <li
            className={`${
              lang === "es" ? "bg-avianca-black-2 text-white rounded-t" : ""
            } px-10 py-2 hover:bg-avianca-gray-3 hover:text-avianca-black-2 hover:rounded-t`}
          >
            <a href="/#" id="es" onClick={handleLangSelect}>
              {t("translation:langSelect.es")}
            </a>
          </li>
          <li
            className={`${
              lang === "en" ? "bg-avianca-black-2 text-white rounded-b" : ""
            } px-10 py-2 hover:bg-avianca-gray-3 hover:text-avianca-black-2 hover:rounded-b`}
          >
            <a href="/#" id="en" onClick={handleLangSelect}>
              {t("translation:langSelect.en")}
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default LangSelect
