import gifLoader from "../../assets/gif/git-loader.gif"

const Loader = () => {
  return (
    <>
      <div className="min-h-screen min-w-full bg-white z-10 absolute flex justify-center items-center">
        <img src={gifLoader} alt="Gif loader" />
      </div>
    </>
  )
}

export default Loader
