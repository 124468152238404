import imageInformation from "../assets/images/imageInformation.png"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../redux/hooks"
import { getParamsUrl } from "../redux/paramsUrl/paramsUrlSlice"
import { useState } from "react"

const Information = () => {
  const { paramsUrl, hasParams } = useAppSelector(getParamsUrl)
  const { t } = useTranslation()
  const [disabledButton, setDisabledButton] = useState(false)

  const handleButton = (): void => {
    setDisabledButton(true)
    const baseUrl = import.meta.env.VITE_REACT_APP_MMB_BASE_URL
    const defaultBaseUrl = import.meta.env.VITE_REACT_APP_MMB_BASE_URLXDEFAULT
    if (hasParams) {
      const { lang, lastName, recLoc } = paramsUrl!
      const url = baseUrl
        .replace("{lang}", lang)
        .replace("{pnr}", recLoc)
        .replace("{lastname}", lastName)
      window.location.href = url
    } else {
      const url = defaultBaseUrl.replace("{lang}", paramsUrl?.lang)
      window.location.href = url
    }
  }

  return (
    <>
      <div className="container px-4 flex flex-row lg:items-center lg:justify-center tallInitial:mt-2 tallInitial:mb-14">
        <div className="flex justify-center items-center drop-shadow-[0_-1px_3px_rgba(0,0,0,0.1)] min-h-max m-auto">
          <div className="bg-white w-full max-w-[1024px] flex flex-col lg:flex-row lg:flex-wrap lg:justify-between lg:items-center p-6 pt-5 lg:pt-[114px] lg:pb-[113px] lg:px-[96px]">
            <div className="lg:hidden flex flex-col w-full lg:w-auto max-w-[536px] text-left ">
              <h2 className="font-avianca-bold text-avianca-black-1 text-2xl font-bold">
                {t("translation:Information.youRate")}
              </h2>
            </div>
            <div className="flex justify-center">
              <img
                src={imageInformation}
                alt="Imagen de error"
                className="mx-2 lg:mx-0 mt-8 lg:mt-0 max-h-[146px] max-w-[151px] sm:max-w-[227px] sm:max-h-[234px]"
              />
            </div>
            <div className="pb-[24px] lg:pb-0 max-w-[536px]">
              <div className="hidden lg:flex flex-col w-full max-w-[536px] text-left">
                <h2 className="font-avianca-bold text-avianca-black-1 font-bold text-[40px] leading-[48px]">
                  {t("translation:Information.youRate")}
                </h2>
              </div>
              <div className="mt-8 lg:mt-6">
                <p className="font-avianca text-avianca-black-1 text-sm lg:text-2xl tracking-tight">
                  {t("translation:Information.ifYouNeed")}
                </p>
              </div>
            </div>
            <div className="w-full flex justify-end">
              <button
                className={`w-full md:mt-2 py-4 
                ${
                  !disabledButton ? "bg-avianca-red-1" : "bg-avianca-red-2"
                } rounded font-avianca-bold text-white text-sm font-bold lg:w-[194px] 
                ${
                  !disabledButton ? "hover:bg-avianca-red-3" : ""
                } active:bg-avianca-red-2 self-end whitespace-nowra`}
                type="button"
                onClick={handleButton}
              >
                {t("translation:Information.buyLuggage")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Information
