import { useTranslation } from "react-i18next"

const ManagementHeader = ({ isActiveCheck }: { isActiveCheck: boolean }) => {
  const { t } = useTranslation()
  return (
    <>
      <h2 className="font-avianca-bold text-avianca-black-2 text-xl md:text-2xl">
        {t("translation:Management.title")}
      </h2>
      <p
        className={`my-3 font-avianca text-avianca-black-2 text-sm md:text-base ${
          !isActiveCheck ? "mb-[24px]" : " "
        }`}
      >
        {t("translation:Management.information.detail")}
      </p>
    </>
  )
}

export default ManagementHeader
