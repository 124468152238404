import { useCallback } from "react"
import i18n from "../i18n"
import { Params } from "../models/general"
import { useAppDispatch } from "../redux/hooks"
import { setParamsUrlState } from "../redux/paramsUrl/paramsUrlSlice"
import { handleLang } from "../utils/helpers/helperLang"

const useHandleParams = () => {
  const dispatch = useAppDispatch()

  const getParams = (): Params => {
    const params: Params = {
      lang: "",
      lastName: "",
      pointOfSale: "",
      recLoc: "",
      showOrderEligibilities: "",
    }
    const queryParams = new URLSearchParams(window.location.search)
    for (const key in params) {
      if (!queryParams.has(key)) continue
      const newValue: string = queryParams.get(`${key}`) ?? ""
      params[key as keyof Params] = newValue
    }
    return params
  }

  const handleSetlang = useCallback(() => {
    const params: Params = getParams()
    if (!params.lang) {
      params.lang = "es"
    }
    if (params.lang) {
      handleLang(params.lang?.toLocaleLowerCase())
    }
    i18n.changeLanguage(params.lang?.toLocaleLowerCase())
    if (params.recLoc && params.lastName) {
      dispatch(setParamsUrlState(params))
    }
    return true
  }, [dispatch])

  return {
    handleSetlang,
    getParams,
  }
}
export default useHandleParams
