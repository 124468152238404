import imageError from "../assets/images/imageError.png"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useAppSelector } from "../redux/hooks"
import { getParamsUrl } from "../redux/paramsUrl/paramsUrlSlice"

const PageError = () => {
  const { paramsUrl } = useAppSelector(getParamsUrl)
  const { state } = useLocation()
  const { t } = useTranslation()
  const [disabledButton, setDisabledButton] = useState(false)

  const handleButton = (): void => {
    setDisabledButton(true)
    const baseUrl = import.meta.env.VITE_REACT_APP_MMB_BASE_URL
    const defaultBaseUrl = import.meta.env.VITE_REACT_APP_MMB_BASE_URLXDEFAULT
    if (state?.paramsUrl) {
      const { lang, lastName, recLoc } = state.paramsUrl
      const url = baseUrl
        .replace("{lang}", lang)
        .replace("{pnr}", recLoc)
        .replace("{lastname}", lastName)
      window.location.href = url
    } else {
      const url = defaultBaseUrl.replace("{lang}", paramsUrl?.lang)
      window.location.href = url
    }
  }

  return (
    <>
      <div className="container px-4 flex flex-row lg:items-center lg:justify-center tallInitial:my-2 tallInitial:mb-14">
        <div className="flex justify-center items-center lg:w-[1024px] drop-shadow-[0_-1px_3px_rgba(0,0,0,0.1)] min-h-max m-auto">
          <div className="rounded bg-white flex flex-col lg:flex-row lg:flex-wrap p-6 lg:pt-[107px] lg:pb-[108px] lg:pl-[56px] lg:pr-[48px]">
            <div className="lg:hidden flex flex-col w-full lg:w-auto max-w-[560px] text-left ">
              <h2 className="font-avianca-bold text-avianca-black-1 text-2xl font-bold">
                {t("translation:Error.sorry")}
              </h2>
            </div>
            <div className="flex justify-center">
              <img
                src={imageError}
                alt="Imagen de error"
                className="mx-4 lg:mx-0 mt-6 lg:mt-0 w-[208px] h-[138px] lg:w-[293px] lg:h-[194px] "
              />
            </div>
            <div className="pt-[22px] lg:pt-0 max-w-[560px] lg:ml-[67px]">
              <div className="hidden lg:flex flex-col w-full max-w-[560px] text-left">
                <h2 className="font-avianca-bold text-avianca-black-1 font-bold text-[40px] leading-[52px]">
                  {t("translation:Error.sorry")}
                </h2>
              </div>
              <div className="mt-6">
                <p className="font-avianca text-avianca-black-1 text-sm lg:text-xl lg:leading-[26.46px] tracking-tight lg:mr-6">
                  {t("translation:Error.thereIsAProblem")}
                </p>
              </div>
              <button
                className={`w-full mt-8 lg:mt-10 h-[48px] leading-4 tracking-[0.8px] lg:text-[16px]
                ${
                  !disabledButton ? "bg-avianca-red-1" : "bg-avianca-red-2"
                } rounded font-avianca-bold text-white text-sm font-bold lg:w-auto lg:px-6 
                ${
                  !disabledButton ? "hover:bg-avianca-red-3" : ""
                } active:bg-avianca-red-2 self-end whitespace-nowrap`}
                type="button"
                onClick={handleButton}
                disabled={disabledButton}
              >
                {t("translation:Error.reManage")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageError
